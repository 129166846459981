import { Block, Overview } from "../../Home/Home.styled";
import { SavingsData } from '../../../data/OtherServicesData';
import SavingsListTable from "./SavingsListTable";

export const Savings = () => {

    const loanTitleStyle = {
        fontSize: "24px",
        marginBottom: "20px",
    };

    const savings = [
        {
            title: 'Certificate of Deposit',
            period: 'Short-term interest rate at 14%',
            interestRate: 'Min. deposit from $10,000.00',
            maxLoanAmount: 'No monthly maintenance fee'
        },
        {
            title: 'High Yield Savings',
            period: 'Annual percentage yield 4.30%',
            interestRate: 'No min. deposit required',
            maxLoanAmount: 'No monthly maintenance fee'
        },
        {
            title: 'Basic Savings',
            period: 'Interest Rate at 8%',
            interestRate: 'Min. deposit from $5,000.00',
            maxLoanAmount: 'Monthly maintenance fee $10.00'
        },
    ]
    return (
        <Overview style={{ flexDirection: 'column', alignSelf: 'center' }}>
            <div style={{ width: '100%' }}>
                <h2>Savings</h2>
                <hr />
                <p>Here is the list of available savings account offers</p>
                <Overview style={{ width: '100%', paddingBottom: '10px' }}>
                    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex' }}>
                        <div style={{ display: 'inline-block', minWidth: '300px', margin: '10px' }}>
                            <Block style={{ minWidth: '300px', height: '160px', justifyContent: 'center', textAlign: 'center', color: '#FC6E45', fontSize: '100px', cursor: 'pointer' }} >+</Block>
                        </div>
                        {savings.map((savings, index) => (
                            <div key={index} style={{ display: 'inline-block', margin: '10px' }}>
                                <Block style={{ minWidth: '300px' }}>
                                    <p style={loanTitleStyle}>{savings.title}</p>
                                    <ul style={{ paddingLeft: '20px' }}>
                                        <li>{savings.period}</li>
                                        <li>{savings.interestRate}</li>
                                        <li>{savings.maxLoanAmount}</li>
                                    </ul>
                                </Block>
                            </div>
                        ))}
                    </div>
                </Overview>
                <hr />
                <div style={{ marginBottom: '10px' }}>
                    <h2>Savings List</h2>
                    <p>Here is the list of requested and offered savings</p>
                </div>
                <SavingsListTable savings={SavingsData} />
            </div>
        </Overview>
    )
}

export default Savings