const theme = {
	dark: {
		background: "#101B2f",
		buttonMain: "#FC6E45",
		text: "#FFF",
		secondaryText: "#9AAAB2",
		backgroundSections: "#242D41",
	},
	light: {
		background: "rgb(240, 240, 240)",
		buttonMain: "#FC6E45",
		text: "#000",
		secondaryText: "gray",
		backgroundSections: "white",
	},
};

export default theme;
