import styled from "styled-components";

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupContent = styled.div`
  background-color: #101B2f;
  border-radius: 20px;
  border-color: #9AAAB2;
  border-style: solid;
  border-width: unset;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  padding: 20px;
  max-width: 1100px; 
  width: 80%;
`;

export const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const PopupTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PopupCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #FF6448;
`;

export const PopupForm = styled.form`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const PopupInputContainer = styled.div`
width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
`;

export const PopupLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  color: #ffff;
`;

export const PopupInput = styled.input`
  padding: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  font-size: 16px;
  background-color: #fff; 
  color: #000;
  width:100%
`;



export const PopupButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PopupCancelButton = styled.button`
  background-color: transparent;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;


export const PopupSendAndAdd = styled.div`
display: flex;
align-items:center;
width:50%;
justify-content:space-between;
height: 50px;
border-radius: 50%;
cursor: pointer;
margin-top: 20px;
float: inline-start;
`

export const PopupSendButton = styled.button`
  background-color: #FF6448;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

export const AddAnother = styled.p`
  background-color: transparent;
  color: #FF6448;
  border-radius: 5px;
  width: 100px; 
  height: 40px; 
  cursor: pointer;
  font-size: 14px;
`;