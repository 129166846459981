import { createContext, useContext, useState } from 'react';

const ChurnRateContext = createContext();

export function ChurnRateProvider({ children }) {
    const [isChurnRate, setIsChurnRate] = useState(false);

    return (
        <ChurnRateContext.Provider value={{ isChurnRate, setIsChurnRate }}>
            {children}
        </ChurnRateContext.Provider>
    );
}

export function useChurnRate() {
    return useContext(ChurnRateContext);
}
