import React, { createContext, useContext, useState } from 'react';

const ReportContext = createContext();

export const useReportContext = () => useContext(ReportContext);

export const ReportProvider = ({ children }) => {
    const [selectedReportType, setSelectedReportType] = useState('');

    return (
        <ReportContext.Provider value={{ selectedReportType, setSelectedReportType }}>
            {children}
        </ReportContext.Provider>
    );
};
