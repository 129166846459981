import styled from "styled-components";

export const Button = styled.button`
	background-color: ${(props) => props.theme.buttonMain};
	padding: 10px 20px;
	color: ${(props) => props.theme.text};
	border-radius: 30px;
	border: none;
`;

export const LoginButton = styled.button`
	background-color: #fc6e45;
	padding: 15px 20px;
	color: ${(props) => props.theme.text};
	box-shadow: 0px 16px 30px 0px rgba(27, 31, 68, 0.08);
	border-radius: 16px;
	margin-top: 20px;
	width: 80%;
	border: none;
	cursor: pointer;
`;
