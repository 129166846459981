import styled from "styled-components";

export const Background = styled.div`
  width: 155px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0px 16px 30px 0px rgba(27, 31, 68, 0.08);
  overflow-y: auto;
  z-index: 100;

  @media (max-width: 768px) {
    width: 100%; 
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media (max-width: 1397px) {
    height:100%
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display:none;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  padding-left: 5px;
  position: relative;
  overflow: hidden;

  scrollbar-width: none;
  -ms-overflow-style: none;

  /* Responsive Styles */
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 10px;
  justify-content:center;
  width:100%;
  border-right: 5px solid transparent; 
  ${props => props.isClient && `
  border-right-color: #FF6448;
`}
  &:hover {
    fill: white;
  }
`;

export const Icon = styled.svg`
  width: 30px;
  height: 30px;
  fill: ${(props) => (props.isActive ? "white" : "#879CA7")};
  margin-right: 10px;

  &:hover {
    fill: white;
  }
`;

export const IconText = styled.div`
  font-size: 14px;
  color: #879CA7;
  padding: 5px;
`;

export const BankLogoContainer = styled.div`
  margin-top: 10px;
`;

export const Footer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  @media (max-width: 768px) {
    display: flex;
    flex:1;
  }
`;

export const MaxWidthLink = styled.a`
width:100%;
`