import React, { useState } from "react";
import { Container, SearchbarContainer, SearchbarInput } from "./Clients.styled";


const SearchBar = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  return (
    <Container style={{ justifyContent: 'flex-end' }}>
      <SearchbarContainer >
        <SearchbarInput
          type="text"
          placeholder="Search.."
          value={searchQuery}
          onChange={handleSearch}

        />
      </SearchbarContainer>
    </Container>
  );
};

export default SearchBar;
