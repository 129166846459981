import React, { useState, useEffect } from "react";
import { Overview } from "../Home/Home.styled";
import { SquareContainer } from "../Analytics/Analyticsempty.styled";
import { ReportsContainer, SeeReport, SelectMenu } from "./Reports.styled";
import ClientReportNames from "./ClientsReportNames";
import ClientsData from "../../data/ClientsData";
import { useNavigate } from "react-router-dom";
import IndustryReportNames from "./IndustryReportNames";
import IndustryData from "../../data/IndustryData";
import { useReportContext } from './ReportContext';

const Reports = () => {
    const [filteredClients, setFilteredClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { selectedReportType, setSelectedReportType } = useReportContext();
    const [isReport, setIsReport] = useState('');
    const [isIndustry, setIsIndustry] = useState('');
    const navigate = useNavigate();

    const filterActiveClients = () => {
        const activeClients = ClientsData.filter(client => client.status === "ACTIVE");
        setFilteredClients(activeClients);
    };

    const handleClientSelect = (event) => {
        const selectedClientId = event.target.value;
        setSelectedClient(selectedClientId);
    };

    const handleIndustrySelect = (event) => {
        const selectedIndustryId = event.target.value;
        setSelectedIndustry(selectedIndustryId)
    }

    const handleClientSelectReport = () => {
        if (selectedClient) {
            setSelectedReportType(selectedReportType);
            navigate(`/reports/${selectedClient}/clients`, { state: { isReport } });
        } else {
            alert("Please select a client before generating the report.");
        }
    };

    const handleIndustrySelectReport = () => {
        if (selectedIndustry) {
            setSelectedReportType(selectedIndustry);
            navigate(`/reports/${selectedIndustry}/industry`, { state: { isIndustry } });
        } else {
            alert("Please select an industry before generating the report.");
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        filterActiveClients();
    }, []);
    return (
        <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
            <Overview style={{ width: windowWidth > 1536 ? '100%' : "90%" }}>
                <div style={{ width: '100%' }}>
                    <h1>Reports</h1>
                    <hr style={{ width: '100%', marginBottom: '20px' }} />
                    <div>Select the Client or Industry and the Report type to generate the report.</div>
                </div>
            </Overview>

            <Overview style={{ flexDirection: "row", flexWrap: "nowrap" }}>
                <ReportsContainer>
                    <SquareContainer style={{
                        flexDirection: "column", backgroundColor: "#242D41"
                    }}>
                        <div style={{ paddingBottom: "30px" }}>
                            <h2>Clients Reports</h2>
                        </div>
                        <div style={{ paddingBottom: "30px", marginLeft: "2px", marginRight: "2px" }}>
                            <SelectMenu onChange={handleClientSelect}>
                                <option value="">Select Client</option>
                                {filteredClients.map(client => (
                                    <option key={client.id} value={client.id}>{client.name}</option>
                                ))}
                            </SelectMenu>
                        </div>
                        <div>Select Report</div>
                        <ClientReportNames
                            setReportName={setIsReport} />
                        <SeeReport onClick={handleClientSelectReport}>See the Report</SeeReport>
                    </SquareContainer>
                </ReportsContainer>
                <ReportsContainer style={{ marginRight: "0" }}>
                    <SquareContainer style={{
                        flexDirection: "column", backgroundColor: "#242D41"
                    }}>
                        <div style={{ paddingBottom: "30px" }}>
                            <h2>Industry Reports</h2>
                        </div>
                        <div style={{ paddingBottom: "30px", marginLeft: "2px", marginRight: "2px" }}>
                            <SelectMenu onChange={handleIndustrySelect}>
                                <option value="">Select Industry</option>
                                {IndustryData.map(industry => (
                                    <option key={industry.id} value={industry.id}>{industry.name}</option>
                                ))}
                            </SelectMenu>
                        </div>
                        <div>Select Report</div>
                        <IndustryReportNames setIndustryName={setIsIndustry} />
                        <SeeReport onClick={handleIndustrySelectReport}>See the Report</SeeReport>
                    </SquareContainer>
                </ReportsContainer>
            </Overview>
        </div>
    );
}

export default Reports;
