import { createSlice } from "@reduxjs/toolkit";
import WriteToLocalStorage from "../../../utils/WriteToLocalStorage";

const storageUser = localStorage.geneQTY_user;
const initialState = storageUser ? storageUser : null;

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		addUser: (state, action) => {
			WriteToLocalStorage("geneQTY_user", action.payload);
			return (state = action.payload);
		},
		removeUser: () => {
			localStorage.removeItem("geneQTY_user");
			return null;
		},
	},
});

export const userSliceState = (state) => state.user;
export const { addUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
