import { Block, Overview } from "../../Home/Home.styled";
import { RewardsData } from '../../../data/OtherServicesData';
import { useState } from "react";
import RewardsListTable from "./RewardsListTable";
import NewRewardProgram from "./NewRewardProgram";

export const BusinessRewardProgram = () => {
    const [isCreate, setIsCreate] = useState(false);


    const handleCreate = () => {
        setIsCreate(!isCreate);
    }

    const loanTitleStyle = {
        fontSize: "24px",
        marginBottom: "20px",
    };
    const rewardProgram = [
        {
            title: 'Pay With Rewards',
            period: 'Rewards per collected points',
            interestRate: 'Base rate for all purchases',
            maxLoanAmount: 'Fixed fee'
        },
        {
            title: 'Cash Back',
            period: 'Cash Back rewards',
            interestRate: 'Rewards for bonus categories',
            maxLoanAmount: 'Capped points'
        },
        {
            title: 'Travel Bonus',
            period: 'Cash Back and Miles rewards',
            interestRate: 'No fees or interest',
            maxLoanAmount: 'No Blackout dates'
        },
    ]
    return (
        <>
            {isCreate && (
                <NewRewardProgram handleCreate={handleCreate} />
            )}
            <Overview style={{
                alignSelf: 'center', display: 'flex', flexDirection: 'column', alignContent: 'stretch', alignItems: 'stretch',
            }}>
                {!isCreate && (

                    <Overview style={{ flexDirection: 'column', alignSelf: 'center' }}>
                        <div style={{ width: '100%' }}>
                            <h2>Business Reward Programs</h2>
                            <hr />
                            <p>Here is the list of active business reward programs</p>
                            <Overview style={{ width: '100%', paddingBottom: '10px' }}>
                                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex' }}>
                                    <div style={{ display: 'inline-block', minWidth: '300px', margin: '10px' }}>
                                        <Block style={{ minWidth: '300px', height: '160px', justifyContent: 'center', textAlign: 'center', color: '#FC6E45', fontSize: '100px', cursor: 'pointer' }} onClick={handleCreate}>+</Block>
                                    </div>
                                    {rewardProgram.map((rewardProgram, index) => (
                                        <div key={index} style={{ display: 'inline-block', margin: '10px' }}>
                                            <Block style={{ minWidth: '300px' }}>
                                                <p style={loanTitleStyle}>{rewardProgram.title}</p>
                                                <ul style={{ paddingLeft: '20px' }}>
                                                    <li>{rewardProgram.period}</li>
                                                    <li>{rewardProgram.interestRate}</li>
                                                    <li>{rewardProgram.maxLoanAmount}</li>
                                                </ul>
                                            </Block>
                                        </div>
                                    ))}
                                </div>
                            </Overview>
                            <hr />
                            <div style={{ marginBottom: '10px' }}>
                                <h2>Business Reward List</h2>
                                <p>Here is the list of business reward program clients</p>
                            </div>
                            <RewardsListTable rewards={RewardsData} />
                        </div>
                    </Overview>
                )}
            </Overview>
        </>
    )
}
export default BusinessRewardProgram;