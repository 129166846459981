import React, { useState } from "react";
import { CenteredButton, CustomInput, InputContainer, InputFields, Label } from "../../Clients/Clients.styled";
import { Overview } from "../../Home/Home.styled";

const NewLoanOffer = ({ handleCreate, setNewLoanData }) => {
    const [formData, setFormData] = useState({ title: "", period: "", interestRate: "", maxLoanAmount: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        const newLoanData = JSON.parse(localStorage.getItem("newLoanData")) || [];
        newLoanData.push(formData);
        localStorage.setItem("newLoanData", JSON.stringify(newLoanData));
        setNewLoanData(newLoanData);
        handleCreate();
    };

    const renderInputField = (label, name, value) => (
        <InputFields>
            <Label>{label}:</Label>
            <CustomInput
                type="text"
                name={name}
                value={value}
                onChange={handleChange}
            />
        </InputFields>
    );

    return (
        <Overview style={{ flexDirection: "column", width: "100%" }}>
            <h1>New Loan Offer</h1>
            <InputContainer style={{ margin: '40px 0 20px 0' }}>
                {renderInputField("Loan Name", "title", formData.title)}
                {renderInputField("Maximum Amount", "maxLoanAmount", formData.maxLoanAmount)}
                {renderInputField("Duration", "period", formData.period)}
                {renderInputField("Interest Rate", "interestRate", formData.interestRate)}
                <InputFields>
                    <Label>Interest Rate Type:</Label>
                    <CustomInput type="text" />
                </InputFields>
                {renderInputField("Guarantee Fee", "guaranteeFee", formData.guaranteeFee)}
                {renderInputField("Grace Period", "gracePeriod", formData.gracePeriod)}
                {renderInputField("Deferment", "deferment", formData.deferment)}
            </InputContainer>
            <CenteredButton onClick={handleSubmit}>Create</CenteredButton>
        </Overview>
    );
};

export default NewLoanOffer;
