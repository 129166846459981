const paths = {
	home: "/",
	login: "/login",
	not_found: "*",
	clients: "/clients",
	analyticsempty: "/:id/analyticsempty",
	cfo: "/cfo",
	cfonotification: "/cfo/:id/notification",
	analyticsactive: "/:id/analyticsactive",
	requestedloans: "/requestedloans",
	clientloandetails: "/clientloandetails",
	reports: "/reports",
	clientreports: "/reports/:id/clients",
	industryreports: "/reports/:id/industry",
	otherservices: "/otherservices",
	loans: "/otherservices/loans",
	rewardprograms: "/otherservices/rewardprograms",
	rewarddetails: "/otherservices/rewarddetails",
	savings: "/otherservices/savings",
	investments: "/otherservices/investments",
	moneymarketaccounts: "/otherservices/moneymarketaccounts"
};

export default paths;
