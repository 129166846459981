import { ScoreBip, ActiveCashFlowBipSvg, ActiveProfitAndLossBipSvg, ScoreElixir, ActiveProfitAndlossElixirSvg, ActiveCashFlowElixir, ScoreEmotion, ActiveProfitAndLossEmotionSvg, ActiveCashFlowEmotionSvg, ActiveNetCashElixirSvg, ActiveCashInOutElixirSvg, ActiveCashInOutEmotionSvg, ActiveNetCashEmotionSvg } from "../svg/AnalyticsActiveSvg";
import { KpiBip, KpiElixir, KpiEmotionProduction } from "./KpiValueData";
import { BipAccounts, ElixirAccounts, EmotionAccounts } from "./BankAccountsData";
import { BipBalanceSheetReport, BipCashFlowReport, BipExpenseAllocationReport, BipPnlReport, ElixirPnLReport, EmotionPnLReport } from "../svg/ReportsSvg";
import { AverageManufacturingBeverage, AverageStoreGeneral, AverageTransport } from "./KpiAverage";
import { BipKpiGraphs, ElixirKpiGraphs, EmotionKpiGraphs } from "./KpiGraphs";
export const ClientsData = [
    {
        id: 1,
        name: "BIP",
        industry: "Brewery",
        status: "ACTIVE",
        invited: "06/10/2022",
        bhs: 74,
        totalbalance: "1,303,680.12",
        message: "Good!",
        score: <ScoreBip />,
        pnl: <ActiveProfitAndLossBipSvg />,
        cashflow: <ActiveCashFlowBipSvg />,
        pnlamount: "255,121.00",
        kpi: KpiBip,
        netcashgraph: <ActiveNetCashElixirSvg />,
        accounts: BipAccounts,
        cashinoutgraph: <ActiveCashInOutElixirSvg />,
        netcash: "$211,497.63",
        cashinout: "$575,671.00 / $815,227.40",
        pnlreport: <BipPnlReport />,
        totalchange: "+10.75%",
        pnlchange: "+12.00%",
        kpiaverage: AverageManufacturingBeverage,
        kpigraphs: BipKpiGraphs,
        cashflowreport: <BipCashFlowReport />,
        balancesheetreport: <BipBalanceSheetReport />,
        expenseallocationreport: <BipExpenseAllocationReport />
    },
    {
        id: 2,
        name: "Color Press Group",
        industry: "Consumer services",
        status: "INACTIVE",
        invited: "-",
        bhs: "-",
    },
    {
        id: 3,
        name: "Comtrade",
        industry: "Technology",
        status: "ACTIVE",
        invited: "03/15/2023",
        bhs: 78,
        totalbalance: "1,303,680.12",
        message: "Good!",
        score: <ScoreBip />,
        pnl: <ActiveProfitAndLossBipSvg />,
        cashflow: <ActiveCashFlowBipSvg />,
        pnlamount: "255,121.00",
        kpi: KpiBip,
        netcashgraph: <ActiveNetCashElixirSvg />,
        accounts: BipAccounts,
        cashinoutgraph: <ActiveCashInOutElixirSvg />,
        netcash: "$211,497.63",
        cashinout: "$575,671.00 / $815,227.40",
        pnlreport: <EmotionPnLReport />,
        totalchange: "+12.00%",
        pnlchange: "+12.00%",
        kpiaverage: AverageManufacturingBeverage,
        kpigraphs: BipKpiGraphs,
        cashflowreport: <BipCashFlowReport />,
        balancesheetreport: <BipBalanceSheetReport />,
        expenseallocationreport: <BipExpenseAllocationReport />
    },
    {
        id: 4,
        name: "Delta DMD",
        industry: "Consumer goods",
        status: "INACTIVE",
        invited: "-",
        bhs: "-",
    },
    {
        id: 5,
        name: "Elixir",
        industry: "Consumer goods",
        status: "ACTIVE",
        invited: "06/11/2023",
        bhs: 41,
        totalbalance: "84,753.42",
        message: "Low!",
        score: <ScoreElixir />,
        pnl: <ActiveProfitAndlossElixirSvg />,
        cashflow: <ActiveCashFlowElixir />,
        pnlamount: "-14,367.00",
        kpi: KpiElixir,
        netcashgraph: <ActiveNetCashElixirSvg />,
        accounts: ElixirAccounts,
        cashinoutgraph: <ActiveCashInOutElixirSvg />,
        netcash: "$211,497.63",
        cashinout: "$575,671.00 / $815,227.40",
        pnlreport: <ElixirPnLReport />,
        totalchange: "+2.00%",
        pnlchange: "-3.62%",
        kpiaverage: AverageStoreGeneral,
        kpigraphs: ElixirKpiGraphs,
        cashflowreport: <BipCashFlowReport />,
        balancesheetreport: <BipBalanceSheetReport />,
        expenseallocationreport: <BipExpenseAllocationReport />

    },
    {
        id: 6,
        name: "Emotion",
        industry: "Transport",
        status: "ACTIVE",
        invited: "11/22/2022",
        bhs: 89,
        totalbalance: "503,282.65",
        message: "Good!",
        score: <ScoreEmotion />,
        pnl: <ActiveProfitAndLossEmotionSvg />,
        cashflow: <ActiveCashFlowEmotionSvg />,
        pnlamount: "158,374.15",
        kpi: KpiEmotionProduction,
        netcashgraph: <ActiveNetCashEmotionSvg />,
        accounts: EmotionAccounts,
        cashinoutgraph: <ActiveCashInOutEmotionSvg />,
        netcash: "$677,329.28",
        cashinout: "$1,368,499.71 / $720,378.87 ",
        pnlreport: <EmotionPnLReport />,
        totalchange: "+6.15%",
        pnlchange: "+4.05%",
        kpiaverage: AverageTransport,
        kpigraphs: EmotionKpiGraphs,
        cashflowreport: <BipCashFlowReport />,
        balancesheetreport: <BipBalanceSheetReport />,
        expenseallocationreport: <BipExpenseAllocationReport />
    },
    {
        id: 7,
        name: "FCA",
        industry: "Consumer goods",
        status: "INACTIVE",
        invited: "04/25/2023",
        bhs: "-",
    },
    {
        id: 8,
        name: "Forma Ideale",
        industry: "Consumer goods",
        status: "INACTIVE",
        invited: "06/10/2022",
        bhs: "-",
    },
    {
        id: 9,
        name: "Hemofarm",
        industry: "Health care",
        status: "ACTIVE",
        invited: "02/19/2023",
        bhs: 63,
        totalbalance: "1,303,680.12",
        message: "Good!",
        score: <ScoreBip />,
        pnl: <ActiveProfitAndLossBipSvg />,
        cashflow: <ActiveCashFlowBipSvg />,
        pnlamount: "255,121.00",
        kpi: KpiEmotionProduction,
        netcashgraph: <ActiveNetCashElixirSvg />,
        accounts: BipAccounts,
        cashinoutgraph: <ActiveCashInOutElixirSvg />,
        netcash: "$211,497.63",
        cashinout: "$575,671.00 / $815,227.40",
        pnlreport: <EmotionPnLReport />,
        totalchange: "+6.15%",
        pnlchange: "+4.05%",
        kpiaverage: AverageTransport,
        kpigraphs: BipKpiGraphs,
        cashflowreport: <BipCashFlowReport />,
        balancesheetreport: <BipBalanceSheetReport />,
        expenseallocationreport: <BipExpenseAllocationReport />

    },
    {
        id: 10,
        name: "Ikarbus",
        industry: "Industrials",
        status: "INACTIVE",
        invited: "06/10/2022",
        bhs: "-",
    },
    {
        id: 11,
        name: "Impol",
        industry: "Basic materials",
        status: "ACTIVE",
        invited: "06/10/2023",
        bhs: 76,
        totalbalance: "1,303,680.12",
        message: "Good!",
        score: <ScoreBip />,
        pnl: <ActiveProfitAndLossBipSvg />,
        cashflow: <ActiveCashFlowBipSvg />,
        pnlamount: "255,121.00",
        kpi: KpiEmotionProduction,
        netcashgraph: <ActiveNetCashElixirSvg />,
        accounts: BipAccounts,
        cashinoutgraph: <ActiveCashInOutElixirSvg />,
        netcash: "$211,497.63",
        cashinout: "$575,671.00 / $815,227.40",
        pnlreport: <EmotionPnLReport />,
        totalchange: "+6.15%",
        pnlchange: "+4.05%",
        kpiaverage: AverageTransport,
        kpigraphs: BipKpiGraphs,
        cashflowreport: <BipCashFlowReport />,
        balancesheetreport: <BipBalanceSheetReport />,
        expenseallocationreport: <BipExpenseAllocationReport />

    },
    {
        id: 12,
        name: "Joberty LLC",
        industry: "Technology",
        status: "ACTIVE",
        invited: "05/23/2023",
        bhs: 74,
        totalbalance: "1,303,680.12",
        message: "Good!",
        score: <ScoreBip />,
        pnl: <ActiveProfitAndLossBipSvg />,
        cashflow: <ActiveCashFlowBipSvg />,
        pnlamount: "255,121.00",
        kpi: KpiEmotionProduction,
        netcashgraph: <ActiveNetCashElixirSvg />,
        accounts: BipAccounts,
        cashinoutgraph: <ActiveCashInOutElixirSvg />,
        netcash: "$211,497.63",
        cashinout: "$575,671.00 / $815,227.40",
        pnlreport: <EmotionPnLReport />,
        totalchange: "+6.15%",
        pnlchange: "+4.05%",
        kpiaverage: AverageTransport,
        kpigraphs: BipKpiGraphs,
        cashflowreport: <BipCashFlowReport />,
        balancesheetreport: <BipBalanceSheetReport />,
        expenseallocationreport: <BipExpenseAllocationReport />

    },
    {
        id: 13,
        name: "Jaffa",
        industry: "Consumer goods",
        status: "INACTIVE",
        invited: "07/13/2022",
        bhs: "-",
    },
    {
        id: 14,
        name: "La Fantana",
        industry: "Consumer goods",
        status: "INACTIVE",
        invited: "-",
        bhs: "-",
    },
    {
        id: 15,
        name: "Lukoil",
        industry: "Oil & gas",
        status: "INACTIVE",
        invited: "07/13/2023",
        bhs: "-",
    },
    {
        id: 16,
        name: "Maxi",
        industry: "Consumer services",
        status: "ACTIVE",
        invited: "06/19/2023",
        bhs: 68,
        totalbalance: "1,303,680.12",
        message: "Good!",
        score: <ScoreBip />,
        pnl: <ActiveProfitAndLossBipSvg />,
        cashflow: <ActiveCashFlowBipSvg />,
        pnlamount: "255,121.00",
        kpi: KpiEmotionProduction,
        netcashgraph: <ActiveNetCashElixirSvg />,
        accounts: BipAccounts,
        cashinoutgraph: <ActiveCashInOutElixirSvg />,
        netcash: "$211,497.63",
        cashinout: "$575,671.00 / $815,227.40",
        pnlreport: <EmotionPnLReport />,
        totalchange: "+6.15%",
        pnlchange: "+4.05%",
        kpiaverage: AverageTransport,
        kpigraphs: BipKpiGraphs,
        cashflowreport: <BipCashFlowReport />,
        balancesheetreport: <BipBalanceSheetReport />,
        expenseallocationreport: <BipExpenseAllocationReport />

    },
    {
        id: 17,
        name: "Mercator",
        industry: "Consumer goods",
        status: "INACTIVE",
        invited: "07/13/2023",
        bhs: "-",
    },
];

export default ClientsData;