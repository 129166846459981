import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrangeScore, { GreenScore } from "../../svg/LoansSvg";
import { Description } from "../Analytics/Analyticsempty.styled";
import { HorisontalLine } from "../AnalyticsActive1/AnalyticsActive.styled";
import { SendInvitePopupContainer } from "../ClientsTable/Table.styled";
import { Overview } from "../Home/Home.styled";
import { GrayButton, GreenButton, LoansBlock, LoansButtons, LoansLogo, OrangeButton, PaymentAndRate } from "./RequestedLoans.styled";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

const RequestedLoans = () => {
    const initialLoanData = [
        {
            id: 1,
            isPopupApproved: false,
            isPopupDeclined: false,
            isLoanAssigned: null,
            name: "Forma Ideale",
            monthlyPayment: "1 555",
            rate: "15.00",
            period: 18,
            score: <GreenScore />,
            totalAmount: "25 000"
        },
        {
            id: 2,
            isPopupApproved: false,
            isPopupDeclined: false,
            isLoanAssigned: null,
            name: "Jaffa",
            monthlyPayment: "1 597",
            rate: "15.00",
            period: 36,
            score: <OrangeScore />,
            totalAmount: "50 000"

        },
        {
            id: 3,
            isPopupApproved: false,
            isPopupDeclined: false,
            isLoanAssigned: null,
            name: "Comtrade",
            monthlyPayment: "1 893",
            rate: "12.00",
            period: 24,
            score: <OrangeScore />,
            totalAmount: "41 000"

        },
        {
            id: 4,
            isPopupApproved: false,
            isPopupDeclined: false,
            isLoanAssigned: null,
            name: "Bip",
            monthlyPayment: "2 597",
            rate: "15.00",
            period: 12,
            score: <GreenScore />,
            totalAmount: "90 000"

        },
    ];

    const [loanData, setLoanData] = useState(initialLoanData);
    const navigate = useNavigate();
    const handleApprovedPopup = (id) => {
        setLoanData((prevLoanData) =>
            prevLoanData.map((loanItem) =>
                loanItem.id === id
                    ? {
                        ...loanItem,
                        isPopupApproved: true,
                        isLoanAssigned: true,
                    }
                    : loanItem
            )
        );

        setTimeout(() => {
            setLoanData((prevLoanData) =>
                prevLoanData.map((loanItem) =>
                    loanItem.id === id
                        ? {
                            ...loanItem,
                            isPopupApproved: false,
                        }
                        : loanItem
                )
            );
        }, 3000);
    };

    const handleDeclinedPopup = (id) => {
        setLoanData((prevLoanData) =>
            prevLoanData.map((loanItem) =>
                loanItem.id === id
                    ? {
                        ...loanItem,
                        isPopupDeclined: true,
                        isLoanAssigned: false,
                    }
                    : loanItem
            )
        );

        setTimeout(() => {
            setLoanData((prevLoanData) =>
                prevLoanData.map((loanItem) =>
                    loanItem.id === id
                        ? {
                            ...loanItem,
                            isPopupDeclined: false,
                        }
                        : loanItem
                )
            );
        }, 3000);
    };

    return (
        <div style={{ alignSelf: 'center' }} >
            <HorisontalLine />
            <Overview>
                <h1>Requested Loans</h1>
                <Description>Here is the list of requested loans for review</Description>
                {loanData.map((loanItem) => (
                    <LoansBlock key={loanItem.id}>
                        <div>
                            <LoansLogo>{loanItem.name.charAt(0)}</LoansLogo>
                            <div>
                                <Description>Simple Credit</Description>
                                <div>Cash Loan</div>
                            </div>
                        </div>
                        <PaymentAndRate>
                            <h4>{loanItem.name}</h4>
                            <Description>Monthly Payment ${loanItem.monthlyPayment}</Description>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Description>Rate</Description>
                                    <div>From {loanItem.rate}%</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Description>Period</Description>
                                    <div>{loanItem.period} Months</div>
                                </div>
                            </div>
                        </PaymentAndRate>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    {loanItem.score}
                                    <p style={{ marginLeft: "20px" }}>Bussiness Health Score</p>
                                </div>
                                <div style={{ fontSize: "x-large" }}>${loanItem.totalAmount}</div>
                            </div>
                            {loanItem.isLoanAssigned === null && (
                                <LoansButtons>
                                    <GrayButton onClick={() => navigate("/clientloandetails")}>See details</GrayButton>
                                    <GreenButton onClick={() => handleApprovedPopup(loanItem.id)}>Approve</GreenButton>
                                    <OrangeButton onClick={() => handleDeclinedPopup(loanItem.id)}>Decline</OrangeButton>
                                </LoansButtons>
                            )}

                            {loanItem.isLoanAssigned === true && (
                                <LoansButtons>
                                    <GreenButton>Approved!</GreenButton>
                                </LoansButtons>
                            )}

                            {loanItem.isLoanAssigned === false && (
                                <LoansButtons>
                                    <OrangeButton>Declined!</OrangeButton>
                                </LoansButtons>
                            )}
                        </div>
                    </LoansBlock>
                ))}
            </Overview>
            {loanData.map((loanItem) => (
                <div key={loanItem.id}>
                    {loanItem.isPopupApproved && (
                        <SendInvitePopupContainer>
                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                style={{ width: "30px", height: "30px", color: "#ff6600" }}
                            />
                            The loan has been approved. Notification has been sent to the client.
                        </SendInvitePopupContainer>
                    )}

                    {loanItem.isPopupDeclined && (
                        <SendInvitePopupContainer>
                            <FontAwesomeIcon
                                icon={faCircleXmark}
                                style={{ width: "30px", height: "30px", color: "#ff6600" }}
                            />
                            The loan has been declined. Notification has been sent to the client.
                        </SendInvitePopupContainer>
                    )}
                </div>
            ))}
        </div>
    );
};

export default RequestedLoans;
