
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    removeUser,
} from "../../store/features/userSlice/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeaderLayout, MenuButton, MenuContainer, MenuIcons, MenuItem } from "./Header.styled";
import { useLocation } from "react-router-dom";
import { PiBellThin, PiSignOutThin, PiUserThin } from 'react-icons/pi'
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { SendInvitePopupContainer } from "../../pages/ClientsTable/Table.styled";
import paths from "../../routes/paths";
const Header = () => {
    const [isPopupApproved] = useState(false);
    const [isPopupDeclined] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const dispatch = useDispatch();
    const logOut = () => dispatch(removeUser());
    const location = useLocation();
    const showWelcomeMessage = location.pathname === paths.home
    if (location.pathname === '/login') return;
    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <HeaderLayout>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", paddingTop: "15px" }}>
                        {showWelcomeMessage && (
                            <div>
                                <h1>Hello Dionne</h1>
                                <div>Hope you're having a wonderful day!</div>
                            </div>
                        )}
                        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <MenuButton onClick={handleToggleMenu}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </MenuButton>
                            {isMenuOpen && (
                                <>
                                    <MenuContainer open={isMenuOpen}>
                                        <MenuItem>Profile</MenuItem>
                                        <MenuItem>Notification</MenuItem>
                                        <MenuItem onClick={logOut}>Sign Out</MenuItem>
                                    </MenuContainer>
                                </>
                            )}
                        </div>
                    </div>
                    <HeaderLayout style={{ justifyContent: 'flex-end' }}>
                        <MenuIcons>
                            <PiUserThin
                                style={{ width: "30px", height: "30px" }}
                            />
                            <PiBellThin
                                style={{ width: "30px", height: "30px" }}
                            />
                            <PiSignOutThin
                                onClick={logOut}
                                style={{ width: "30px", height: "30px" }}
                            />
                        </MenuIcons>
                    </HeaderLayout>
                </HeaderLayout>
                {isPopupApproved && (
                    <SendInvitePopupContainer>
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            style={{ width: "30px", height: "30px", color: "#ff6600" }}
                        />
                        The loan has been approved. Notification has been sent to the client.
                    </SendInvitePopupContainer>
                )}

                {isPopupDeclined && (
                    <SendInvitePopupContainer>
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{ width: "30px", height: "30px", color: "#ff6600" }}
                        />
                        The loan has been declined. Notification has been sent to the client.
                    </SendInvitePopupContainer>
                )}
            </div >
        </>
    )
}

export default Header;