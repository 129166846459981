import styled from "styled-components";

export const LoansBlock = styled.div`
    width: 100%;
    height: 200px;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0px 8px 16px rgba(27, 31, 68, 0.04);
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    padding: 0 40px;
    margin-bottom: 20px;
`


export const LoansLogo = styled.div`
    width: 100px;
    height: 70px;
    border-radius: 20%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color:black;
    font-weight: bold;
    font-size: 40px;
`

export const LoansButtons = styled.div`
    display:flex;
    flex-direction: row;
`


const ButtonBase = styled.div`
  display: flex;
  width: 200px;
  height: 30px;
  border-radius: 40px;
  margin-left: 20px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const GrayButton = styled(ButtonBase)`
  background-color: #9AAAB2;
`;
export const WhiteButton = styled(ButtonBase)`
  background-color: #FFFFFF;
  color: black;
`;

export const GreenButton = styled(ButtonBase)`
  background-color: #5C8938;
`;

export const OrangeButton = styled(ButtonBase)`
  background-color: #FF6448;
`;


export const PaymentAndRate = styled.div`
display:flex;
flex-direction: column;
width: 300px;
height: 80%;
justify-content: space-evenly;
`