// App.js
import React from "react";
import GlobalStyles from "./components/GlobalStyles/GlobalStyles.styled";
import Routes from "./routes/Routes";
import theme from "./themes/Theme";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { themeSliceState } from "./store/features/themeSlice/themeSlice";
import LeftSideBar from "./components/LeftSideBar/LeftSideBar";
import Header from "./components/Header/Header";
import { AppProvider } from "./AppContext";
import { Body } from "./globalstyles/App.styled";
import { ChurnRateProvider } from "./pages/cfo/ChurnRateContext ";
import { ReportProvider } from "./pages/Reports/ReportContext"

const App = () => {
	const themeVariant = useSelector(themeSliceState);

	return (
		<ThemeProvider theme={theme[themeVariant]}>
			<ChurnRateProvider>
				<ReportProvider>
					<AppProvider>
						<GlobalStyles />
						<div style={{ display: "flex", }}>
							<LeftSideBar />
							<Body>
								<Header />
								<Routes />
							</Body>
						</div>
					</AppProvider>
				</ReportProvider>
			</ChurnRateProvider>
		</ThemeProvider>
	);
};

export default App;
