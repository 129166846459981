
export const KpiBip =
{
    debtToEquity: "0.86",
    debtratio: "0.90",
    operatingprofitmargin: "9",
    netprofitmargin: "5",
    returnonasset: "1.16",
    returnonequity: "1.43",
    currentratio: "2.13",
    quickratio: "1.34",
    cashratio: "0.72",
    inventoryturnover: "2",
    grossprofitmargin: "20"
}
export const KpiElixir =
{
    debtToEquity: "0.71",
    debtratio: "0.83",
    operatingprofitmargin: "2.02",
    netprofitmargin: "2.36",
    returnonasset: "5.00",
    returnonequity: "18.51",
    inventoryturnover: "26.00",
    grossprofitmargin: "21.13",
    currentratio: "0.61",
    quickratio: "0.19",
    cashratio: "0.28",
}
export const KpiEmotionProduction =
{
    debtToEquity: "0.79",
    debtratio: "0.56",
    operatingprofitmargin: "7.66",
    netprofitmargin: "4.71",
    returnonasset: "6.40",
    returnonequity: "23.08",
    inventoryturnover: "2.00",
    grossprofitmargin: "80.00",
    currentratio: "2.07",
    quickratio: "0.72",
    cashratio: "0.61",
}
