import React from "react";
import { useRoutes } from "react-router-dom";
import paths from "./paths";
import ProtectedRoute from "./ProtectedRoute";
import LoginRoute from "./LoginRoute";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import NotFound from "../components/NotFount/NotFound";
import Clients from "../pages/Clients/Clients"
import Analyticsempty from "../pages/Analytics/Analyticsempty";
import AnalyticsActive1 from "../pages/AnalyticsActive1/AnalyticsActive"
import RequestedLoans from "../pages/RequestedLoans/RequestedLoans"
import ClientLoanDetails from "../pages/ClientLoanDetails/ClientLoanDetails"
import Reports from "../pages/Reports/Reports"
import ClientReports from "../pages/Reports/ClientReports"
import IndustryReports from "../pages/Reports/IndustryReports";
import CfoNotifications from "../pages/cfo/CfoNotifications";
import ChurnRateNotification from "../pages/cfo/ChurnRateNotification";
import OtherServices from "../pages/OtherServices/OtherServices";
import ServiceLoans from "../pages/OtherServices/Loans/ServiceLoans";
import BusinessRewardProgram from "../pages/OtherServices/BusinessReward/BusinessRewardProgram";
import RewardsDetails from "../pages/OtherServices/BusinessReward/RewardsDetails";
import Savings from "../pages/OtherServices/Savings/Savings";
import Investments from "../pages/OtherServices/Investments/Investments";
import MoneyMarketAccounts from "../pages/OtherServices/MoneyMarket/MoneyMarketAccounts";
const Routes = () => {
	const element = useRoutes([
		{
			path: paths.home,
			element: (
				<ProtectedRoute>
					<Home />
				</ProtectedRoute>
			),
		},
		{
			path: paths.login,
			element: (
				<LoginRoute>
					<Login />
				</LoginRoute>
			),
		},

		{
			path: paths.clients,
			element: (
				<ProtectedRoute>
					<Clients />
				</ProtectedRoute>
			),
		},
		{
			path: paths.analyticsempty,
			element: (
				<ProtectedRoute>
					<Analyticsempty />
				</ProtectedRoute>
			),
		},
		{
			path: paths.cfo,
			element: (
				<ProtectedRoute>
					<CfoNotifications />
				</ProtectedRoute>
			),
		},
		{
			path: paths.cfonotification,
			element: (
				<ProtectedRoute>
					<ChurnRateNotification />
				</ProtectedRoute>
			),
		},
		{
			path: paths.analyticsactive,
			element: (
				<ProtectedRoute>
					<AnalyticsActive1 />
				</ProtectedRoute>
			),
		},
		{
			path: paths.requestedloans,
			element: (
				<ProtectedRoute>
					<RequestedLoans />
				</ProtectedRoute>
			),
		},
		{
			path: paths.clientloandetails,
			element: (
				<ProtectedRoute>
					<ClientLoanDetails />
				</ProtectedRoute>
			),
		},
		{
			path: paths.reports,
			element: (
				<ProtectedRoute>
					<Reports />
				</ProtectedRoute>
			),
		},
		{
			path: paths.clientreports,
			element: (
				<ProtectedRoute>
					<ClientReports />
				</ProtectedRoute>
			),
		},
		{
			path: paths.industryreports,
			element: (
				<ProtectedRoute>
					<IndustryReports />
				</ProtectedRoute>
			),
		},
		{
			path: paths.otherservices,
			element: (
				<ProtectedRoute>
					<OtherServices />
				</ProtectedRoute>
			),
		},
		{
			path: paths.loans,
			element: (
				<ProtectedRoute>
					<ServiceLoans />
				</ProtectedRoute>
			),
		},
		{
			path: paths.rewardprograms,
			element: (
				<ProtectedRoute>
					<BusinessRewardProgram />
				</ProtectedRoute>
			),
		},
		{
			path: paths.rewarddetails,
			element: (
				<ProtectedRoute>
					<RewardsDetails />
				</ProtectedRoute>
			),
		},
		{
			path: paths.savings,
			element: (
				<ProtectedRoute>
					<Savings />
				</ProtectedRoute>
			),
		},
		{
			path: paths.investments,
			element: (
				<ProtectedRoute>
					<Investments />
				</ProtectedRoute>
			),
		},
		{
			path: paths.moneymarketaccounts,
			element: (
				<ProtectedRoute>
					<MoneyMarketAccounts />
				</ProtectedRoute>
			),
		},

		// Create other routes bellow this line

		// Not found component should
		// always be last (convention)
		{
			path: paths.not_found,
			element: (
				<ProtectedRoute>
					<NotFound />
				</ProtectedRoute>
			),
		},
	]);

	return element;
};

export default Routes;
