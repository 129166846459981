import React, { useRef, useEffect, useState } from "react";
import {
    PopupContainer,
    PopupContent,
    PopupHeader,
    PopupTitle,
    PopupCloseButton,
    PopupForm,
    PopupLabel,
    PopupInputContainer,
    PopupInput,
    PopupButtons,
    PopupCancelButton,
    PopupSendButton,
    PopupSendAndAdd,
    AddAnother,
} from './InviteClients.styled';
import { SendInvitePopupContainer } from "../ClientsTable/Table.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTrashCan } from "@fortawesome/free-solid-svg-icons";


let clientIdCounter = 40;
const Popup = ({ onClose, onSendInvite }) => {
    const popupRef = useRef();
    const [industries, setIndustries] = useState([""]);
    const [names, setNames] = useState([""]);
    const [forms, setForms] = useState([{ id: 1 }]);
    const [inviteSentPopup, setInviteSentPopup] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const addForm = () => {
        if (forms.length < 5) {
            const newForm = { id: forms.length + 1 };
            setForms([...forms, newForm]);
            setNames([...names, ""]); // Initialize a new name field
            setIndustries([...industries, ""]); // Initialize a new industry field
        }
    };

    const removeForm = (id) => {
        if (forms.length > 1) {
            const updatedForms = forms.filter((form) => form.id !== id);
            setForms(updatedForms);

            // Remove the corresponding name and industry fields
            setNames(names.filter((_, index) => index !== id - 1));
            setIndustries(industries.filter((_, index) => index !== id - 1));
        }
    };

    const handleSendInvite = () => {
        const companyNameInputs = document.querySelectorAll('input[name^="companyName"]');
        const emailInput = document.querySelector('input[name="email"]');
        clientIdCounter++;
        const newClients = [];

        companyNameInputs.forEach((input, index) => {
            const companyName = input.value;
            const email = emailInput.value.trim();
            if (!companyName || !email) {
                alert("Please fill in the required fields: Company Name and Email address.");
                return;
            }

            const newClient = {
                id: clientIdCounter,
                industry: industries[index],
                name: names[index],
                email: email,
                status: "INACTIVE",
                bhs: "-",
                invited: new Date().toLocaleDateString(),
            };
            newClients.push(newClient);
        });

        // Get the existing stored clients from local storage
        const storedClients = JSON.parse(localStorage.getItem("storedclients")) || [];

        // Add the new clients to the array of stored clients
        storedClients.push(...newClients);

        // Update the stored clients in local storage
        localStorage.setItem("storedclients", JSON.stringify(storedClients));

        setInviteSentPopup(true);
        setTimeout(() => {
            setInviteSentPopup(false);
            onClose();
        }, 3000);
    };

    return (
        <>
            {!inviteSentPopup && (
                <PopupContainer>
                    <PopupContent ref={popupRef}>
                        <PopupHeader>
                            <PopupTitle>Send an email invite to your clients</PopupTitle>
                            <PopupCloseButton onClick={onClose}>&#10006;</PopupCloseButton>
                        </PopupHeader>
                        <PopupForm style={{ marginRight: "70px" }}>
                            <PopupInputContainer>
                                <PopupLabel>Company Name</PopupLabel>
                            </PopupInputContainer>
                            <PopupInputContainer>
                                <PopupLabel>Industry</PopupLabel>
                            </PopupInputContainer>
                            <PopupInputContainer>
                                <PopupLabel>Email address</PopupLabel>
                            </PopupInputContainer>
                        </PopupForm>
                        {forms.map((form, index) => (
                            <PopupForm key={form.id}>
                                <PopupInputContainer>
                                    <PopupInput type="text"
                                        placeholder="Type here"
                                        name={`companyName_${form.id}`}
                                        value={names[index]}
                                        onChange={(e) => {
                                            const newValues = [...names];
                                            newValues[index] = e.target.value;
                                            setNames(newValues);
                                        }}
                                        required />
                                </PopupInputContainer>
                                <PopupInputContainer>
                                    <PopupInput type="text"
                                        placeholder="Type here"
                                        name={`industry_${form.id}`}
                                        value={industries[index]}
                                        onChange={(e) => {
                                            const newValues = [...industries];
                                            newValues[index] = e.target.value;
                                            setIndustries(newValues);
                                        }}
                                        required />
                                </PopupInputContainer>
                                <PopupInputContainer>
                                    <PopupInput type="email" placeholder="Type here" name="email" required />
                                </PopupInputContainer>
                                <PopupButtons>
                                    <PopupCancelButton onClick={() => removeForm(form.id)}><FontAwesomeIcon icon={faTrashCan} style={{ color: "#ff6648", }} /></PopupCancelButton>
                                </PopupButtons>
                            </PopupForm>
                        ))}
                        <PopupSendAndAdd>
                            <AddAnother onClick={addForm}>+ Add</AddAnother>
                            <PopupSendButton onClick={handleSendInvite}>Send</PopupSendButton>
                        </PopupSendAndAdd>
                    </PopupContent>
                </PopupContainer>
            )}
            {inviteSentPopup && (
                <SendInvitePopupContainer>
                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ width: "30px", height: "30px", color: '#ff6600' }}
                    />
                    Invite(s) sent!
                </SendInvitePopupContainer>
            )
            }
        </>
    );
};

export default Popup;