import React from "react";
import {
	Block,
	Client,
	List,
	Overview,
	TopLeftText,
	CenteredText,
	OrangeText,
	DataSquare,
	BHSRowContainer,
	ClientName,
	BHSScore,
	FilledBar,
	ScoreBar,
	PerformanceNumbers,
} from "./Home.styled";
import { NonPerfomingLoans } from "../../svg/HomeSvg";
import { useAppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import useClientPopup from "../../hooks/useClientPopup";
import Popup from "../Clients/InviteClients"
import { SquareContainer } from "../Analytics/Analyticsempty.styled";
import { CardContainer, HorisontalLine } from "../AnalyticsActive1/AnalyticsActive.styled";
import HomeRecentNotification from './HomeRecentNotification'
const Home = () => {
	const { setSelectedBHSRange, setIsActive } = useAppContext();
	const { isPopupOpen, openPopup, closePopup, popupRef } = useClientPopup();
	const navigate = useNavigate();

	const updateBHSRange = (range) => {
		setSelectedBHSRange(range);
		setIsActive("active")
		navigate("/clients");
	};
	const DataRow = ({ title, subtitle, amount }) => (
		<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
			<div>
				<p style={{ fontSize: "16px" }}>{title}</p>
				<OrangeText>{subtitle}</OrangeText>
			</div>
			<div style={{ fontSize: "16px" }}>${amount}</div>
		</div>
	);

	const BHSRows = ({ name, score }) => {
		const fillWidth = `${score}%`;

		return (
			<BHSRowContainer>
				<div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginBottom: "10px" }}>
					<ClientName>
						<div style={{ fontSize: "14px" }}>{name}</div>
					</ClientName>
					<BHSScore>
						<div style={{ fontSize: "14px" }}>BHS{score}</div>
					</BHSScore>
				</div>
				<ScoreBar>
					<FilledBar fillWidth={fillWidth}></FilledBar>
				</ScoreBar>
			</BHSRowContainer>
		);
	};
	const topClientsData = [
		{
			title: "Hemofarm",
			subtitle: "Healthcare",
			amount: "3,257,262.01"
		},
		{
			title: "Sinofarm",
			subtitle: "Healthcare",
			amount: "1,408,600.73"
		},
		{
			title: "Mercator",
			subtitle: "Consumer Goods",
			amount: "1,936,207.67"
		},
		{
			title: "Maxi",
			subtitle: "Consumer Goods",
			amount: "1,812,738.14"
		},
		{
			title: "Lukoil",
			subtitle: "Oil & Gas",
			amount: "957,135.60"
		},
	]
	const TopDebtros = [
		{
			title: "Ikarbus",
			subtitle: "Industrials",
			amount: "1,674,201.14"
		},
		{
			title: "Emotion Production",
			subtitle: "Consumer Services",
			amount: "957,756.08"
		},
		{
			title: "Forma Ideale",
			subtitle: "Consumer Goods",
			amount: "551,713.40"
		},
		{
			title: "Jaffa",
			subtitle: "Consumer Goods",
			amount: "312,558.40"
		},
		{
			title: "La Fantana",
			subtitle: "Consumer Goods",
			amount: "227,462.55"
		},
	]
	const LowestBhs = [
		{
			name: "FCA",
			score: "62"
		},
		{
			name: "Impol",
			score: "59"
		},
		{
			name: "Ikarbus",
			score: "55"
		},
		{
			name: "La Fantana",
			score: "50"
		},
	]
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
			<Overview style={{ justifyContent: 'space-between' }}>
				<span>See your performance at a glance</span>
				<div>
					<Client onClick={openPopup}>+ INVITE A CLIENT</Client>
					<List>IMPORT NEW LIST</List>
				</div>
			</Overview >

			<Overview>
				<PerformanceNumbers>
					<Block>
						<TopLeftText>No. of New Clients</TopLeftText>
						<CenteredText onClick={() => updateBHSRange([0, 100])}>8</CenteredText>
					</Block>
					<Block>
						<TopLeftText>No. of Insolvent clients</TopLeftText>
						<CenteredText onClick={() => updateBHSRange([0, 70])}>3</CenteredText>
					</Block>
					<Block>
						<TopLeftText>No. of New Savings Accounts</TopLeftText>
						<CenteredText onClick={() => navigate('/otherservices/savings', { state: "ACTIVE" })}>7</CenteredText>
					</Block>
					<Block>
						<TopLeftText>No. of Requested Loans</TopLeftText>
						<CenteredText onClick={() => navigate('/otherservices/loans', { state: "Requested" })}>3</CenteredText>
					</Block>
				</PerformanceNumbers>
			</Overview>
			<Overview>
				<SquareContainer style={{ justifyContent: "space-between" }}>
					<CardContainer style={{ margin: "10px 0px", textAlign: "start" }}>
						<div style={{ width: "100%" }}>
							<div style={{ paddingBottom: "20px" }}>
								<p style={{ fontSize: "16px", fontWeight: "bold" }}>Top 5 Clients</p>
								<OrangeText>Based on the monthly revenue</OrangeText>
							</div>
							<DataSquare>
								{topClientsData.map((item, index) => (
									<div key={index}>
										<DataRow
											title={item.title}
											subtitle={item.subtitle}
											amount={item.amount}
										/>
										{index !== topClientsData.length - 1 && (
											<HorisontalLine style={{ margin: "0" }} />
										)}
									</div>
								))}
							</DataSquare>
						</div>
					</CardContainer>
					<CardContainer style={{ margin: "10px 0px", textAlign: "start" }}>
						<div style={{ width: "100%" }}>
							<div style={{ paddingBottom: "20px" }}>
								<h4>Top 5 Debtors</h4>
								<OrangeText>Based on the debt amount</OrangeText>
							</div>
							<DataSquare>
								{TopDebtros.map((item, index) => (
									<div key={index}>
										<DataRow
											title={item.title}
											subtitle={item.subtitle}
											amount={item.amount}
										/>
										{index !== TopDebtros.length - 1 && (
											<HorisontalLine style={{ margin: "0" }} />
										)}
									</div>
								))}
							</DataSquare>
						</div>
					</CardContainer>
					<CardContainer style={{ margin: "10px 0px", textAlign: "start" }}>
						<div style={{ width: "100%" }}>
							<div style={{ paddingBottom: "20px" }}>
								<p style={{ fontSize: "20px", fontWeight: "bold" }}>Clients with lowest BHS</p>
								<OrangeText>Considering the average BHS per industry</OrangeText>
							</div>
							<DataSquare>
								{LowestBhs.map((item, index) => (
									<div key={index}>
										<BHSRows
											name={item.name}
											score={item.score}
										/>
									</div>
								))}
							</DataSquare>
						</div>
					</CardContainer>
				</SquareContainer>
			</Overview>
			<Overview style={{ justifyContent: "space-around", flexWrap: 'nowrap' }}>
				<SquareContainer style={{ width: "auto", paddingRight: '30px' }}>
					<NonPerfomingLoans />
				</SquareContainer>
				<HomeRecentNotification customStyles={{ maxWidth: "420px", textAlign: "start" }} />
			</Overview>
			{
				isPopupOpen && (
					<Popup
						ref={popupRef}
						onClose={closePopup}
						onSendInvite={openPopup}
					/>
				)
			}
		</div>
	);
};

export default Home;
