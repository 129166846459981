import { CompanyInfo, Description } from "../Analytics/Analyticsempty.styled"
import { WhiteCircle, CardContainer, BlackLetter } from "./AnalyticsActive.styled"
import { useParams } from "react-router-dom"
import ClientsData from "../../data/ClientsData";


const clients = ClientsData;
const WhiteCircleWithLetter = ({ letter }) => (
    <WhiteCircle>
        <BlackLetter>{letter}</BlackLetter>
    </WhiteCircle>
);
const CompanyInfoCard = ({ handleBankVisibility, customStyles, isFlipped, setIsFlipped, isAccounts }) => {
    const { id } = useParams();
    const client = clients.find((client) => client.id.toString() === id.trim());
    return (
        <CardContainer
            style={customStyles}
            className={isFlipped ? 'flipped' : ''}
        >
            <CompanyInfo>
                <div>
                    <div>
                        <WhiteCircleWithLetter letter={client.name.charAt(0)} />
                    </div>
                    <div>
                        {client.name}
                        <Description>{client.industry}</Description>
                    </div>
                </div>
                <div style={{ color: client.totalchange.startsWith('-') ? "#D8613F" : "#59b398", flexDirection: "column" }}>
                    <span style={{ alignSelf: "end" }}>{client.totalchange}</span>
                    <Description>Last month</Description>
                </div>

            </CompanyInfo>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div>
                    <Description>Total Bank Balance</Description>
                    {!isAccounts && (

                        <p
                            onClick={() => {
                                setIsFlipped(!isFlipped);
                                handleBankVisibility();
                            }}
                            style={{ color: "#FC6E45", textDecoration: "none", cursor: "pointer" }}
                        >See all Accounts</p>
                    )}
                </div>
                <div style={{ fontSize: "24px" }}>${client.totalbalance}</div>
            </div>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center" }}>
                <div>{client.score}</div>
                <div style={{ textAlign: "center" }}>
                    <div>{client.message} <br />Business Health</div>
                    <Description>This client is doing well.</Description>
                </div>
            </div>
        </CardContainer>
    )
}

export default CompanyInfoCard