import { Block, Overview } from "../../Home/Home.styled";
import { MoneyMarketAccountsData } from "../../../data/OtherServicesData";
import MoneyMarketListTable from "./MoneyMarketListTable";


export const MoneyMarketAccounts = () => {

    const loanTitleStyle = {
        fontSize: "24px",
        marginBottom: "20px",
    };

    const moneymarket = [
    ]
    return (
        <Overview style={{ flexDirection: 'column', alignSelf: 'center' }}>
            <div style={{ width: '100%' }}>
                <h2>Money Market Accounts</h2>
                <hr />
                <p>Here is the list of requested and offered money market accounts</p>
                <Overview style={{ width: '100%', paddingBottom: '10px', justifyContent: 'flex-start' }}>
                    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex' }}>
                        <div style={{ display: 'inline-block', minWidth: '300px', margin: '10px' }}>
                            <Block style={{ minWidth: '300px', height: '160px', justifyContent: 'center', textAlign: 'center', color: '#FC6E45', fontSize: '100px', cursor: 'pointer' }} >+</Block>
                        </div>
                        {moneymarket.map((moneymarket, index) => (
                            <div key={index} style={{ display: 'inline-block', margin: '10px' }}>
                                <Block style={{ minWidth: '300px' }}>
                                    <p style={loanTitleStyle}>{moneymarket.title}</p>
                                    <ul style={{ paddingLeft: '20px' }}>
                                        <li>{moneymarket.period}</li>
                                        <li>{moneymarket.interestRate}</li>
                                        <li>{moneymarket.maxLoanAmount}</li>
                                    </ul>
                                </Block>
                            </div>
                        ))}
                    </div>
                </Overview>
                <hr />
                <div style={{ marginBottom: '10px' }}>
                    <h2>Money Market Accounts List</h2>
                    <p>Here is the list of requested and offered Money Market Accounts</p>
                </div>
                <MoneyMarketListTable moneymarketaccounts={MoneyMarketAccountsData} />
            </div>
        </Overview>
    )
}

export default MoneyMarketAccounts