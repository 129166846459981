import styled from "styled-components";

export const LoginContainer = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const LoginForm = styled.form`
	/* outline: 1px solid red; */
	padding: 20px;
	text-align: center;
	margin: 10px;
	width: 400px;
	max-width: 100%;
`;

export const InputFieldContainer = styled.div`
	width: 100%;
	text-align: center;
`;

export const InputFieldLabel = styled.div`
	margin-bottom: 10px;
	/* font-size: 15px; */
`;

export const InputFieldError = styled.div`
	font-size: 11px;
	color: ${(props) => props.theme.buttonMain};
	min-height: 18.5px;
	margin: 7px 0px 15px 0px;
`;


export const PartnerText = styled.div`
  color: #879CA7;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 129.5%;
`;

export const BankLogo = styled.svg`
	width: 139px;
	height: 93px;
	border-radius: 12px;
	background: #FFF;	
`
