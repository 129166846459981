import React from "react";
import { useLocation } from "react-router-dom";
import {
    Background,
    Menu,
    IconContainer,
    IconText,
    Container,
    BankLogoContainer,
    Footer,
    MaxWidthLink,
} from "./LeftSideBar.styled";
import BankLogo from "./LeftSideBarSvg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHouse,
    faUser,
    faBuildingColumns,
    faFile,
} from "@fortawesome/free-solid-svg-icons";
import paths from "../../routes/paths";
import { RiBarChartFill } from "react-icons/ri"

const LeftSideBar = () => {
    const location = useLocation();
    const isHomePage = location.pathname === paths.home;
    const isLoginPage = location.pathname === "/login";

    if (isLoginPage) {
        return null;
    }
    const isClientPage =
        location.pathname === paths.clients ||
        location.pathname === paths.analyticsactive ||
        location.pathname.match(/^\/\d+\/analyticsactive$/);
    const isRequestedLoansPage = location.pathname === paths.requestedloans;
    const isReportsPage =
        location.pathname === paths.reports ||
        location.pathname.startsWith('/reports/')
    const isCfoPage = location.pathname === paths.cfo ||
        location.pathname === paths.cfonotification ||
        location.pathname.startsWith('/cfo/');
    const isOtherServicesPage = location.pathname === paths.otherservices ||
        location.pathname.startsWith('/otherservices/')
    return (
        <Background>
            <Container>
                <BankLogoContainer>
                    <BankLogo />
                </BankLogoContainer>
                <Menu>
                    <MaxWidthLink href="/">
                        <IconContainer isClient={isHomePage}>
                            <FontAwesomeIcon
                                icon={faHouse}
                                color={isHomePage ? "white" : "#879CA7"}
                                style={{ width: "60px", height: "60px" }}
                            />
                        </IconContainer>
                    </MaxWidthLink>
                    <IconText>Overview</IconText>
                    <MaxWidthLink href="/clients">
                        <IconContainer isClient={isClientPage}>
                            <RiBarChartFill
                                color={isClientPage ? "white" : "#879CA7"}
                                style={{ width: "60px", height: "60px" }}
                            />
                        </IconContainer>
                    </MaxWidthLink>
                    <IconText>Clients</IconText>
                    <MaxWidthLink href="/cfo">
                        <IconContainer isClient={isCfoPage}>
                            <FontAwesomeIcon
                                icon={faUser}
                                color={isCfoPage ? "white" : "#879CA7"}
                                style={{ width: "60px", height: "60px" }}
                            />
                        </IconContainer>
                    </MaxWidthLink>
                    <IconText>CFO</IconText>
                    <MaxWidthLink href="/otherservices">
                        <IconContainer isClient={isOtherServicesPage}>
                            <FontAwesomeIcon
                                icon={faBuildingColumns}
                                color={isOtherServicesPage ? "white" : "#879CA7"}
                                style={{ width: "60px", height: "60px" }}
                            />
                        </IconContainer>
                    </MaxWidthLink>
                    <IconText>Other Services</IconText>
                    <MaxWidthLink href="/reports">
                        <IconContainer isClient={isReportsPage}>
                            <FontAwesomeIcon
                                icon={faFile}
                                color={isReportsPage ? "white" : "#879CA7"}
                                style={{ width: "60px", height: "60px" }}
                            />
                        </IconContainer>
                    </MaxWidthLink>
                    <IconText>Reports</IconText>
                </Menu>
            </Container>
            <Footer>
                <Menu>
                    <a href="/">
                        <IconContainer isHomePage={isHomePage}>
                            <FontAwesomeIcon
                                icon={faHouse}
                                color={isHomePage ? "white" : "#879CA7"}
                                style={{ width: "60px", height: "60px" }}
                            />
                        </IconContainer>
                    </a>
                    <a href="/clients">
                        <IconContainer isClient={isClientPage}>
                            <FontAwesomeIcon
                                icon={faUser}
                                color={isClientPage ? "white" : "#879CA7"}
                                style={{ width: "60px", height: "60px" }}
                            />
                        </IconContainer>
                    </a>
                    <a href="/requestedloans">
                        <IconContainer isClient={isRequestedLoansPage}>
                            <FontAwesomeIcon
                                icon={faBuildingColumns}
                                color={isRequestedLoansPage ? "white" : "#879CA7"}
                                style={{ width: "60px", height: "60px" }}
                            />
                        </IconContainer>
                    </a>
                    <a href="/reports">
                        <IconContainer isClient={isReportsPage}>
                            <FontAwesomeIcon
                                icon={faFile}
                                color={isReportsPage ? "white" : "#879CA7"}
                                style={{ width: "60px", height: "60px" }}
                            />
                        </IconContainer>
                    </a>
                </Menu>
            </Footer>
        </Background>
    );
};

export default LeftSideBar;
