import styled from "styled-components";

export const HeaderLayout = styled.div`
display:flex;
align-items: center;
justify-content: space-between;
padding-top: 10px;
max-width: 1366px;
width:100%;

@media (max-width: 768px) {
    flex: 0 0 calc(50% - 20px);
    min-width:100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  @media (max-width: 1536px){
    width: 90%;
    justify-content:flex-end;
  }
`
export const MenuButton = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  display: none;
  span {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const MenuContainer = styled.div`
display: none;
@media (max-width: 768px) {
  border: 1px solid #ccc;
  padding: 10px;
    background-color: #101b2f;
    border-radius: 10px;
    display:flex;
    flex-direction: column;
  }
`;

export const MenuItem = styled.div`
@media (max-width: 768px) {
  padding: 10px;
}
`;

export const MenuIcons = styled.div`
display:flex;
fl
@media (max-width: 768px){
  display: none;
}
`