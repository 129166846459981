import { ChurnRateLogo } from "../../svg/ChurnRateSvg";
import { CardContainer } from "../AnalyticsActive1/AnalyticsActive.styled";
import { NotificationOverview } from "../Home/Home.styled"
import { AiOutlineWarning } from "react-icons/ai"
import { FaSackDollar } from "react-icons/fa6"
import { BiSolidUserPlus } from 'react-icons/bi'
import { WhiteButton } from '../RequestedLoans/RequestedLoans.styled'
import LiquidityRatio from "./LiquidityRatio";
import { useChurnRate } from './ChurnRateContext ';

const ChurnRateNotification = () => {

    const { isChurnRate } = useChurnRate();
    return (
        <NotificationOverview style={{ alignSelf: 'center', alignItems: 'flex-start' }} >
            {isChurnRate && (
                <div style={{ alignSelf: 'center', display: 'flex' }} >
                    <NotificationOverview>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}><h1>Churn Rate Very High</h1><AiOutlineWarning style={{ fontSize: '32px', color: "#FC6E45" }} /></div>
                        <hr style={{ width: '100%', marginBottom: '20px' }} />
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', }}>
                            <div style={{ display: 'flex', justifyContent: "space-evenly", flexDirection: 'column', width: '40%' }}>
                                <div>Based on given data , seems like Joberty LLC has lost 40% of their customers in the last quarter. </div>
                                <div>Joberty LLC started the quarter with 500 customers and lost 300. They are left with 200 customers, meaning their churn rate was 40%. </div>
                                <div>Below is the list of suggested actions that might help out Joberty LLC in reducing the Churn rate.</div>
                            </div>
                            <ChurnRateLogo style={{ maxWidth: "400px", maxHeight: "400px" }} />
                        </div>
                        <div>
                            <h2>Suggested Actions</h2>
                            <CardContainer style={{ flexDirection: "row", flexWrap: "nowrap", height: "auto", marginLeft: "0px" }}>
                                <FaSackDollar style={{ width: '80px', height: '80px', color: "#FC6E45", margin: "30px" }} />
                                <div>
                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignContent: 'center', marginBottom: '10px' }}><h2>Working Capital Loan</h2><WhiteButton style={{ bacmargin: '0 30px 0 0' }}>Offer</WhiteButton></div>
                                    <div>Working Capital Loan can help the company manage its short-term cash flow needs. This can enable the company to invest in customer retention strategies or marketing campaigns. </div>
                                </div>
                            </CardContainer>
                            <CardContainer style={{ flexDirection: "row", flexWrap: "nowrap", height: "auto", marginLeft: "0px" }}>
                                <BiSolidUserPlus style={{ width: '80px', height: '80px', color: "#FC6E45", margin: "30px" }} />
                                <div>
                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignContent: 'center', marginBottom: '10px' }}><h2>Financial Advisory Service</h2><WhiteButton style={{ bacmargin: '0 30px 0 0' }}>Offer</WhiteButton></div>
                                    <div>Financial Advisory Service can include: budgeting, cash flow analysis and financial planning. Combined, these services can help the company to better manage its resources and allocate funds towards retaining customers effectively. </div>
                                </div>
                            </CardContainer>
                        </div>
                    </NotificationOverview>
                </div>
            )}
            {!isChurnRate && (
                <LiquidityRatio />
            )}
        </NotificationOverview>
    )
}

export default ChurnRateNotification;