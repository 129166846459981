import styled from "styled-components";

export const Input = styled.input`
	background-color: ${(props) => props.theme.backgroundSections};
	/* margin-bottom: 20px; */
	padding: 10px 20px;
	color: ${(props) => props.theme.text};
	border-radius: 30px;
	border: none;
	width: 275px;
	max-width: 100%;
`;
