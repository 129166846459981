import React, { useState } from 'react';
import { CompanyInfo, Description } from "../Analytics/Analyticsempty.styled";
import { BoldTitle, CFONotificationBlock, CFONotificationCard, CardContainer, CenteredButton, NotificationButton } from "./AnalyticsActive.styled";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const RecentCfoNotifications = ({ customStyles }) => {
    const [isFirstCardFlipped, setFirstCard] = useState(false);
    const [isSecondCardFlipped, setSecondCard] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const toggleFirstCard = () => {
        setFirstCard(!isFirstCardFlipped);
    };
    const toggleShinkageCard = () => {
        setSecondCard(!isSecondCardFlipped);
    };



    let content = (
        <>
            <CardContainer style={{ flexDirection: "column", ...customStyles }}>
                <CompanyInfo>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <h4>Recent CFO Notifications</h4>
                        <p style={{ color: "#FC6E45", textDecoration: "none", cursor: 'pointer' }} onClick={() => navigate('/cfo')}>See all</p>
                    </div>
                </CompanyInfo>
                <Description style={{ marginTop: "10px" }}>1 New Notification</Description><br />
                <CFONotificationBlock>
                    <CFONotificationCard className={isFirstCardFlipped ? 'flipped' : ''} style={{ marginTop: "0px" }}>
                        <div>
                            <BoldTitle>
                                <div >Liquidity ratio below optimal</div> <p onClick={toggleFirstCard} style={{ cursor: "pointer" }}>
                                    {isFirstCardFlipped ? <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#000000", }} /> : <FontAwesomeIcon icon={faEye} style={{ color: "#000000", }} />}</p>
                            </BoldTitle>
                            <p>
                                {isFirstCardFlipped ? (
                                    <>
                                        Send a quote for the Working Capital loan: 18 months, 6,5% abr.
                                        <CenteredButton>
                                            <NotificationButton>Send</NotificationButton>
                                        </CenteredButton>
                                    </>
                                ) : (
                                    <>
                                        Your liquidity ratio is lower than the optimal value of 1.1x - have you considered taking a loan from the bank?{' '}
                                    </>
                                )}
                            </p>
                        </div>
                    </CFONotificationCard>
                    <CFONotificationCard>
                        <div>
                            <BoldTitle>
                                <div>Sales trend low</div> &#10006;
                            </BoldTitle>
                            <p>
                                Your sales trend is <b>11% lower</b> than the previous month. Try to
                                learn more about how to improve sales.
                            </p>
                        </div>
                    </CFONotificationCard>
                    <CFONotificationCard className={isSecondCardFlipped ? 'flipped' : ''} style={{ background: "#FC6E45", }} >
                        <div>
                            <BoldTitle>
                                <div>High Inventory Shrinkage </div> <p onClick={toggleShinkageCard} style={{ cursor: "pointer" }}>
                                    {isSecondCardFlipped ? <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#000000", }} /> : <FontAwesomeIcon icon={faEye} style={{ color: "#000000", }} />}</p>
                            </BoldTitle>
                            <p>
                                {isSecondCardFlipped ? (
                                    <>
                                        Book a meeting with an insurance representative.{' '}
                                        <CenteredButton>
                                            <NotificationButton>Book</NotificationButton>
                                        </CenteredButton>
                                    </>
                                ) : (
                                    <>
                                        Your inventory shrinkage costs have increased by 31% in the previous three months. In addition to being able to optimize your supply chain process, you can always insure your assets.{' '}
                                    </>
                                )}

                            </p>
                        </div>
                    </CFONotificationCard>
                </CFONotificationBlock>
            </CardContainer >
        </>
    );

    if (id === "5") {
        content = (
            <CardContainer style={{ flexDirection: "column", ...customStyles }}>
                <CompanyInfo>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <h4>Recent CFO Notifications</h4>
                        <p style={{ color: "#FC6E45", textDecoration: "none", cursor: 'pointer' }} onClick={() => navigate('/cfo')}>See all</p>
                    </div>
                </CompanyInfo>
                <Description style={{ marginTop: "10px" }}>1 New Notification</Description><br />
                <CFONotificationBlock>
                    <CFONotificationCard className={isFirstCardFlipped ? 'flipped' : ''} style={{ marginTop: "0px" }}>
                        <div>
                            <BoldTitle>
                                <div>Cash Flow below optimal</div> <p onClick={toggleFirstCard} style={{ cursor: "pointer" }}>
                                    {isFirstCardFlipped ? <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#000000", }} /> : <FontAwesomeIcon icon={faEye} style={{ color: "#000000", }} />}</p>
                            </BoldTitle>
                            <p>
                                {isFirstCardFlipped ? (
                                    <>
                                        Offer Elixir a short-term cash loan with 12% interest rate for 2 years period.
                                        <CenteredButton>
                                            <NotificationButton>Offer</NotificationButton>
                                        </CenteredButton>
                                    </>
                                ) : (
                                    <>
                                        Elixir’s cash flow has dropped below optimal. You can offer them a loan as a short-term solution.{' '}
                                    </>
                                )}
                            </p>
                        </div>
                    </CFONotificationCard>
                    <CFONotificationCard>
                        <div>
                            <BoldTitle>
                                <div>Return on Equity increase</div> <p>2h Ago</p>
                            </BoldTitle>
                            <p>
                                There has been an increase of 1.47% of Elixir’s Return on Equity compared to last. quarter.
                            </p>
                        </div>
                    </CFONotificationCard>
                </CFONotificationBlock >
            </CardContainer>
        );
    }
    if (id === "6") {
        content = (
            <CardContainer style={{ flexDirection: "column", ...customStyles }}>
                <CompanyInfo>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <h4>Recent CFO Notifications</h4>
                        <p style={{ color: "#FC6E45", textDecoration: "none", cursor: 'pointer' }} onClick={() => navigate('/cfo')}>See all</p>
                    </div>
                </CompanyInfo>
                <Description style={{ marginTop: "10px" }}>1 New Notification</Description><br />
                <CFONotificationBlock>
                    <CFONotificationCard className={isFirstCardFlipped ? 'flipped' : ''} style={{ marginTop: "0px" }}>
                        <div>
                            <BoldTitle>
                                <div>Savings Account Opening</div> <p onClick={toggleFirstCard} style={{ cursor: "pointer" }}>
                                    {isFirstCardFlipped ? <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#000000", }} /> : <FontAwesomeIcon icon={faEye} style={{ color: "#000000", }} />}</p>
                            </BoldTitle>
                            <p>
                                {isFirstCardFlipped ? (
                                    <>
                                        Offer Savings account to be opened with 9% interest rate for 24 months period.
                                        <CenteredButton>
                                            <NotificationButton>Offer</NotificationButton>
                                        </CenteredButton>
                                    </>
                                ) : (
                                    <>
                                        Seems like funds are not withdrawn from Emotions checking account in a while. Consider offering them to open a savings account.{' '}
                                    </>
                                )}
                            </p>
                        </div>
                    </CFONotificationCard>
                    <CFONotificationCard>
                        <div>
                            <BoldTitle>
                                <div>Debt Ratio decrease</div> <p>2h Ago</p>
                            </BoldTitle>
                            <p>
                                There has been a decrease of 2.67 pp of your debt ratio. Great job, keep it going!
                            </p>
                        </div>
                    </CFONotificationCard>
                </CFONotificationBlock >
            </CardContainer>
        );
    }

    return content;
}

export default RecentCfoNotifications;
