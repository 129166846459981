const LoansData = [
    {
        id: 1,
        client: 'Forma Ideale',
        loantype: 'Small business loan',
        status: 'Requested',
    },
    {
        id: 2,
        client: 'Color Press Group',
        loantype: 'Credit Builder',
        status: 'Offered',
    },
    {
        id: 3,
        client: 'Sinofarm',
        loantype: 'Small business loan',
        status: 'Offered',
    },
    {
        id: 4,
        client: 'Hemofarm',
        loantype: 'Debt consolidation',
        status: 'Approved',
    },
    {
        id: 5,
        client: 'Emotion Production',
        loantype: 'Credit builder',
        status: 'Approved',
    },
    {
        id: 6,
        client: 'FCA',
        loantype: 'Mortgage',
        status: 'Declined',
    },
    {
        id: 7,
        client: 'Comtrade',
        loantype: 'Credit builder',
        status: 'Offered',
    },
    {
        id: 8,
        client: 'Joberty LLC',
        loantype: 'Small business loan',
        status: 'Requested',
    },
    {
        id: 9,
        client: 'Maxi',
        loantype: 'Credit builder',
        status: 'Requested',
    },
    {
        id: 10,
        client: 'Mercator',
        loantype: 'Debt consolidation',
        status: 'Approved',
    },
]

export const RewardsData = [
    {
        id: 1,
        client: 'BIP',
        program: 'Cash Back',
        status: 'Requested',
    },
    {
        id: 2,
        client: 'Color Press Group',
        program: 'Purchase points',
        status: 'Active',
    },
    {
        id: 3,
        client: 'Sinofarm',
        program: 'Cash Back',
        status: 'Inactive',
    },
    {
        id: 4,
        client: 'Hemofarm',
        program: 'Pay with rewards',
        status: 'Offered',
    },
    {
        id: 5,
        client: 'Emotion Production',
        program: 'Purchase points',
        status: 'Active',
    },
    {
        id: 6,
        client: 'FCA',
        program: 'Purchase points',
        status: 'Active',
    },
    {
        id: 7,
        client: 'Comtrade',
        program: 'Pay with rewards',
        status: 'Inactive',
    },
    {
        id: 8,
        client: 'Joberty LLC',
        program: 'Cash Back',
        status: 'Requested',
    },
    {
        id: 9,
        client: 'Maxi',
        program: 'Travel Bonus',
        status: 'Active',
    },
    {
        id: 10,
        client: 'Mercator',
        program: 'Purchase points',
        status: 'Inactive',
    },
]


export const SavingsData = [
    {
        id: 1,
        client: 'Emotion',
        program: 'BASIC SAVINGS',
        status: 'ACTIVE',
    },
    {
        id: 2,
        client: 'Maxi',
        program: 'HIGH YIELD SAVINGS',
        status: 'ACTIVE',
    },
    {
        id: 3,
        client: 'Elixir',
        program: 'CERTIFICATE OF DEPOSIT',
        status: 'REQUESTED',
    },
    {
        id: 4,
        client: 'FCA',
        program: 'CERTIFICATE OF DEPOSIT',
        status: 'ACTIVE',
    },
    {
        id: 5,
        client: 'Joberty LLC',
        program: 'BASIC SAVINGS',
        status: 'OFFERED',
    },
    {
        id: 6,
        client: 'Mercator',
        program: 'HIGH YIELD SAVINGS',
        status: 'ACTIVE',
    },
    {
        id: 7,
        client: 'Comtrade',
        program: 'CERTIFICATE OF DEPOSIT',
        status: 'ACTIVE',
    },
    {
        id: 8,
        client: 'BIP',
        program: 'HIGH YIELD SAVINGS',
        status: 'ACTIVE',
    },
    {
        id: 9,
        client: 'Mercator',
        program: 'BASIC SAVINGS',
        status: 'ACTIVE',
    },
]

export const InvestmensData = [
    {
        id: 1,
        client: 'Mercator',
        program: 'STOCKS',
        status: 'ACTIVE',
    },
    {
        id: 2,
        client: 'FCA',
        program: 'BONDS',
        status: 'ACTIVE',
    },
    {
        id: 3,
        client: 'Color Press Group',
        program: 'STOCKS',
        status: 'OFFERED',
    },
    {
        id: 4,
        client: 'Maxi',
        program: 'STOCKS',
        status: 'ACTIVE',
    },
    {
        id: 5,
        client: 'Emotion',
        program: 'BONDS',
        status: 'REQUESTED',
    },
]

export const MoneyMarketAccountsData = [
    {}
]
export default LoansData;