import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {
    Table,
    SortIcon,
    TableContainer,
    TableData,
    InviteButton,
    SendInvitePopupContainer,
} from "./Table.styled";
import useClientsSorting from "../../hooks/useClientsSorting";
import usePopupStates from "../../hooks/usePopupStates";

const ClientsTable = ({ clients, filter }) => {
    const { sortField, sortOrder, handleSort, handleRowClick } = useClientsSorting();
    const { popupStates, handleInviteClick } = usePopupStates();
    const [storedClients, setStoredClients] = useState([]);
    const [inviteTime] = useState(null);
    useEffect(() => {
        // Load the stored clients from local storage when the component mounts
        const storedClients = JSON.parse(localStorage.getItem("storedclients")) || [];
        setStoredClients(storedClients);
    }, []);

    const handleInviteTime = (clientId) => {
        // Update the invite time for the specified client
        const updatedClients = storedClients.map((client) =>
            client.id === clientId ? { ...client, invited: new Date().toLocaleDateString() } : client
        );

        // Update the state and local storage with the updated clients
        setStoredClients(updatedClients);
        localStorage.setItem("storedclients", JSON.stringify(updatedClients));
    };

    const sortedClients = [...clients, ...storedClients].sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];


        if (aValue === bValue) {
            return 0;
        }

        if (sortOrder === "asc") {
            if (aValue === "-" || aValue === undefined) {
                return 1;
            } else if (bValue === "-" || bValue === undefined) {
                return -1;
            } else {
                return aValue < bValue ? -1 : 1;
            }
        } else {
            if (aValue === "-" || aValue === undefined) {
                return -1;
            } else if (bValue === "-" || bValue === undefined) {
                return 1;
            } else {
                return bValue < aValue ? -1 : 1;
            }
        }

    });
    console.log('sorted', sortedClients)
    console.log('stored', storedClients)
    return (
        <TableContainer>
            <Table>
                <thead>
                    <tr>
                        <th onClick={() => handleSort("name")}>
                            Name <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("industry")}>
                            Industry <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("status")}>
                            Status <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th>{/* This column is used for "invite button"*/}</th>
                        <th onClick={() => handleSort("invited")}>
                            Invited <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("bhs")}>
                            BHS <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedClients.map((client) => {
                        // Check if the client should be displayed based on the filter
                        if ((filter === 'active' && client.status === 'ACTIVE') ||
                            (filter === 'inactive' && client.status === 'INACTIVE') ||
                            filter === 'all') {
                            return (
                                <tr key={`${client.id}-${client.name}`}>
                                    <TableData style={{ cursor: "pointer" }} onClick={() => handleRowClick(client)}>
                                        {client.name}
                                    </TableData>
                                    <TableData>{client.industry}</TableData>
                                    <TableData>{client.status}</TableData>
                                    <TableData>
                                        {
                                            client.status === "INACTIVE" && (
                                                <InviteButton onClick={() => {
                                                    handleInviteClick(client);
                                                    if (client.invited === '-') {
                                                        handleInviteTime(client.id);
                                                    }
                                                }}>
                                                    {client.invited === '-' ? "Send invite" : "Resend Invite"}
                                                </InviteButton>
                                            )
                                        }
                                        {popupStates[client.id] && (
                                            <SendInvitePopupContainer>
                                                <FontAwesomeIcon
                                                    icon={faCircleCheck}
                                                    style={{ width: "30px", height: "30px", color: '#ff6600' }}
                                                />
                                                <p>You have successfully sent an invite to {client.name}</p>
                                            </SendInvitePopupContainer>
                                        )}
                                    </TableData>
                                    <TableData>{inviteTime !== null ? inviteTime : client.invited}</TableData>
                                    <TableData>{client.bhs}</TableData>
                                </tr>
                            );
                        } else {
                            // Client doesn't match the filter, so don't render it
                            return null;
                        }
                    })}
                </tbody>
            </Table>
        </TableContainer>
    );
};

export default ClientsTable;