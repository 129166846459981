import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';

export const sharedFont = `
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #FFFFFF;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const TextContainer = styled.div`
  ${sharedFont};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SearchbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SearchbarInput = styled.input`
  width: 259px;
  height: 46px;
  padding: 4px 20px;
  background: #232D40;
  border-radius: 50px;
  border: 1.5px solid #FFFFFF;
  ${sharedFont};
  color: #FFFFFF;
  &::placeholder {
    color: #FFFFFF;
  }
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterButton = styled.button`
  width: 123px;
  height: 46px;
  border-radius: 23px;
  background: #232d4080;
  ${sharedFont};
  font-weight: 500;
  color: ${props => (props.active ? "#white" : "#7E7E7E")}; /* Change text color */
  transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out; /* Adjust transition duration */
  border: 2px solid ${props => (props.active ? "#FC6E45" : "transparent")}; /* Change border color */

  &:focus,
  &:active {
    outline: none;
    border-color: ${props => (props.active ? "#FC6E45" : "transparent")}; /* Change border color */
    color: ${props => (props.active ? "white" : "#7E7E7E")}; 
  }
`;



export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const BhsSlider = styled(Slider)`

.rc-slider-track {
  background-color: #ffff; 
}

.rc-slider-handle {
  background-color: #FF6448;
  border: 2px solid #FF6448;
  opacity:1;
}
`

export const SliderValue = styled.div`
  display: flex;
  justify-content: space-between;
`



export const FilterDropdown = styled.select`
  height: 46px;
  border-radius: 23px;
  width: 300px;
  background: #242D41; 
  ${sharedFont};
  font-weight: 500;
  color: white; 
  transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  border: 2px solid ${props => (props.active ? "#FC6E45" : "transparent")};

  &:focus,
  &:active {
    outline: none;
    border-color: ${props => (props.active ? "#FC6E45" : "transparent")};
    color: white;
  }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    width:50%;
`;

export const Label = styled.label`
    flex: 1;
    text-align: left;
`;

export const CustomInput = styled.input`
    flex: 1;
    height: 36px;
    border-radius: 18px;
    background: #242D41;
    font-family: inherit;
    font-weight: 500;
    color: white;
    transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    border: 2px solid transparent;
    padding: 0 10px;
`;

export const CenteredButton = styled.button`
    height: 36px; 
    border-radius: 18px;
    background: #FC6E45;
    font-weight: 500;
    color: white;
    transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    border: 2px solid transparent;
    display: block;
    margin: 0 auto;
    width: 200px;
    cursor:pointer;
`;

export const InputFields = styled.div`
display:flex;
justify-content: space-between;
max-height:36px;
`