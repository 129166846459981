// usePopup.js
import { useState, useRef, useEffect } from "react";

export const useClientPopup = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const popupRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isPopupOpen && popupRef.current && !popupRef.current.contains(event.target)) {
                closePopup();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isPopupOpen]);

    return {
        isPopupOpen,
        openPopup,
        closePopup,
        popupRef,
    };
};

export default useClientPopup;