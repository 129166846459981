import React, { useState, useEffect } from "react";
import { Overview, Client, List, HeaderOverview } from "../Home/Home.styled";
import ClientsTable from "../ClientsTable/ClientsTable";
import {
    BhsSlider,
    FilterButton,
    FilterButtonContainer,
    Filters,
    SliderValue,
} from "./Clients.styled";
import SearchBar from "./SearchBar";
import "rc-slider/assets/index.css";
import useFilteredClients from "../../hooks/useFilteredClients";
import useSliderRange from "../../hooks/useSliderRange";
import { useAppContext } from "../../AppContext";
import Popup from "./InviteClients";
import useClientPopup from "../../hooks/useClientPopup";
const Clients = () => {
    const { selectedBHSRange, setSelectedBHSRange, isActive } = useAppContext();
    const [searchQuery, setSearchQuery] = useState("");
    const [filter, setFilter] = useState(isActive || "all");
    const [bhsRange, setBHSRange] = useSliderRange(selectedBHSRange);
    const filteredClients = useFilteredClients(searchQuery, filter, bhsRange);
    const { isPopupOpen, openPopup, closePopup, popupRef } = useClientPopup();
    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const handleFilterChange = (setIsActive) => {
        setFilter(setIsActive);
    };
    useEffect(() => {
    }, [filter]);

    const FilterSection = () => (
        <FilterButtonContainer>
            <span>Show</span>
            <FilterButton
                active={filter === "all"}
                onClick={() => handleFilterChange("all")}
            >
                All
            </FilterButton>
            <FilterButton
                active={filter === "active"}
                onClick={() => handleFilterChange("active")}
            >
                Active
            </FilterButton>
            <FilterButton
                active={filter === "inactive"}
                onClick={() => handleFilterChange("inactive")}
            >
                Inactive
            </FilterButton>
        </FilterButtonContainer>
    );

    const handleBHSRangeChange = (newRange) => {
        setBHSRange(newRange);
        setSelectedBHSRange(newRange);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <HeaderOverview>
                <h1>Clients</h1>
                <div>
                    <Client style={{ marginRight: '15px' }} onClick={openPopup}>+ INVITE A CLIENT</Client>
                    <List>IMPORT NEW LIST</List>
                </div>
                <hr style={{ width: '100%' }} />
            </HeaderOverview>
            <HeaderOverview style={{ justifyContent: 'flex-end' }}>
                <SearchBar onSearch={handleSearch} />
            </HeaderOverview>
            <Overview>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ alignSelf: 'center' }}>
                        <p style={{ width: '250px' }}>Here is the list of your clients</p>
                    </div>
                    <Filters style={{ placeContent: 'center' }}>
                        <FilterSection />
                    </Filters>
                    <div>
                        <span style={{ display: "flex", justifyContent: "center" }}>BHS</span>
                        <BhsSlider
                            range
                            min={0}
                            max={100}
                            value={bhsRange}
                            onChange={handleBHSRangeChange}
                            style={{ width: "320px", marginRight: "10px", zIndex: "0" }}
                        />
                        <SliderValue>
                            <span>{bhsRange[0]} </span>
                            <span>{bhsRange[1]}</span>
                        </SliderValue>
                    </div>
                </div>
                <ClientsTable clients={filteredClients} filter={filter} />
            </Overview>
            {
                isPopupOpen && (
                    <Popup
                        ref={popupRef}
                        onClose={closePopup}
                        onSendInvite={openPopup}
                    />
                )
            }
        </div >
    );
};

export default Clients;



