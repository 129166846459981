import React from "react";
import { Circle, Initial, DivideBlocks, H2Margin, DescriptionMargin, AlignCards, AnalyticsTopLeftText, LeftGroupCards, AnalyticsBlock, AnalyticsBottomText, EmptyCompanyInfo, SvgContainers } from "../Analytics/Analyticsempty.styled";
import { CashFlow, ProfitAndLoss } from "../../svg/AnalyticsActive1Svg";
import { CardContainer } from "../AnalyticsActive1/AnalyticsActive.styled";
import { HeaderDetails, LoanContainer, LoanDetails, VerticalLine } from "./ClientLoanDetails.styled";
import { GreenButton, OrangeButton } from "../RequestedLoans/RequestedLoans.styled";
import { Overview } from "../Home/Home.styled";
import { ScoreBip } from "../../svg/AnalyticsActiveSvg";

const liquidityRatios = [
    { title: "Current Ratio", value: "2.13" },
    { title: "Quick Ratio", value: "1.34" },
    { title: "Cash Ratio", value: "0.72" },
];

const profitabilityRatios = [
    { title: "Operating Profit Margin", value: "9%" },
    { title: "Net Profit Margin", value: "5%" },
    { title: "Return on Asset", value: "1.16" },
    { title: "Return on Equity", value: "1.43" },
    { title: "Inventory Turnover", value: "0.90" },
    { title: "Gross Profit Margin", value: "13%" },
];

const debtRatios = [
    { title: "Debt-to-Equity", value: "0.86" },
    { title: "Debt Ratio", value: "0.90" }
];


const AnalyticsBlockGroup = ({ data }) => (
    <AlignCards>
        {data.map((item, index) => (
            <AnalyticsBlock key={index}>
                <AnalyticsTopLeftText>{item.title}</AnalyticsTopLeftText>
                <AnalyticsBottomText>{item.value}</AnalyticsBottomText>
            </AnalyticsBlock>
        ))}
    </AlignCards>
);

const AnalyticsSection = ({ title, data }) => (
    <div>
        <AlignCards>
            <span>{title}</span>
        </AlignCards>
        <AnalyticsBlockGroup data={data} />
    </div>
);

const Analyticsempty = () => {

    return (
        <Overview style={{ alignSelf: 'center', }}>
            <DivideBlocks style={{ flexWrap: "nowrap", flexDirection: 'column', flex: '1' }}>
                <hr style={{ margin: '20px 0 20px 0' }} />
                <EmptyCompanyInfo style={{ justifyContent: 'space-between', flex: '1' }}>
                    <div style={{ display: 'flex' }}>
                        <Circle>
                            <Initial>F</Initial>
                        </Circle>
                        <div>
                            <div>Forma Ideale</div>
                            <p>Consumer goods</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '20px' }}>
                            <div style={{ fontSize: '24px' }}>Business Health</div>
                            <div style={{ fontSize: '12px' }}>This client is doing well.</div>
                        </div>
                        <ScoreBip />
                    </div>
                </EmptyCompanyInfo>
            </DivideBlocks>
            <DivideBlocks style={{ flexDirection: 'column', width: '100%' }}>
                <CardContainer style={{ flexDirection: 'row', height: 'auto', justifyContent: 'center', marginTop: '20px', flex: '1' }}>
                    <LoanContainer style={{ flex: '1' }}>
                        <HeaderDetails style={{ flexDirection: 'row', marginBottom: '20px' }}>
                            <HeaderDetails style={{ flexDirection: 'column' }}>
                                <div>
                                    <h2>Loan Request #28 Details</h2>
                                </div>
                                <div>
                                    <p>Requested on 09-25-2023</p>
                                </div>
                            </HeaderDetails>
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", marginRight: "40px" }}>
                                    <GreenButton>Approve</GreenButton>
                                    <OrangeButton>Decline</OrangeButton>
                                </div>
                            </div>
                        </HeaderDetails>
                        <div style={{ display: 'flex', width: '100%', textAlign: 'center' }}>
                            <LoanDetails>
                                <p style={{ color: "#9AAAB2", fontSize: '20px' }}>Requested Amount</p>
                                <p>$250,000.00</p>
                            </LoanDetails>
                            <VerticalLine />
                            <LoanDetails>
                                <p style={{ color: "#9AAAB2", fontSize: '20px' }}>Loan Type</p>
                                <p>Small Business Loan</p>
                            </LoanDetails>
                            <VerticalLine />
                            <LoanDetails>
                                <p style={{ color: "#9AAAB2", fontSize: '20px' }}>Rate</p>
                                <p>11.00%</p>
                            </LoanDetails>
                            <VerticalLine />
                            <LoanDetails>
                                <p style={{ color: "#9AAAB2", fontSize: '20px' }}>Period</p>
                                <p>12 months</p>
                            </LoanDetails>
                            <VerticalLine />
                            <LoanDetails>
                                <p style={{ color: "#9AAAB2", fontSize: '20px' }}>Annuity Amount</p>
                                <p>$23,125.00</p>
                            </LoanDetails>
                            <VerticalLine />
                            <LoanDetails>
                                <p style={{ color: "#9AAAB2", fontSize: '20px' }}>Total Payment</p>
                                <p>$277,500.00</p>
                            </LoanDetails>
                        </div>
                    </LoanContainer>
                </CardContainer>
                <hr style={{ margin: '20px 0 20px 0' }} />
            </DivideBlocks>
            <DivideBlocks>
                <LeftGroupCards>
                    <h1>KPIs</h1>
                    {[
                        { title: "Liquidity ratios", data: liquidityRatios },
                        { title: "Profitability ratios", data: profitabilityRatios },
                        { title: "Debt ratios", data: debtRatios }
                    ].map((section, index) => (
                        <AnalyticsSection key={index} title={section.title} data={section.data} />
                    ))}
                </LeftGroupCards>
                <div style={{ marginLeft: "10%" }}>
                    <div style={{ marginBottom: '50px' }}>
                        <H2Margin>Cash Flow</H2Margin>
                        <SvgContainers>
                            <CashFlow />
                        </SvgContainers>
                    </div>
                    <div>
                        <H2Margin>Profit and Loss</H2Margin>
                        <DescriptionMargin>10 Aug 2023</DescriptionMargin>
                        <SvgContainers>
                            <ProfitAndLoss />
                        </SvgContainers>
                    </div>
                </div>
            </DivideBlocks>
        </Overview>

    );
};

export default Analyticsempty;
