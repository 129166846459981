import styled from "styled-components";

export const HeaderContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 10%;
  margin-right: 10%;
  padding: 20px;
`;

export const Overview = styled.div`
  display: flex;
  flex-grow: 1; 
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 20px 0;
  flex-wrap: wrap;
  max-width: 1366px;
  width:100%;
  .churn-logo {
    height: 100px; 
    width: auto;  
  }
  @media (max-width: 768px) {
 justify-content:center;
}
 @media (max-width: 1536px) {
  justify-content: center;
  width:90%;
 }
`;


export const HeaderOverview = styled.div`
display: flex;
flex-grow: 1; 
justify-content: space-between;
align-items: center;
padding: 20px 0 20px 0;
flex-wrap: wrap;
max-width: 1366px;
width:100%;
.churn-logo {
  height: 100px; 
  width: auto;  
}
@media (max-width: 768px) {
justify-content:space-between;
}
@media (max-width: 1493px) {
justify-content: space-between;
width:90%;
}
@media (max-width: 1536px) {
justify-content: space-between;
width:90%;
}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
`;

export const Client = styled.button`
  background-color: #FF6448;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px; 
  cursor: pointer;
  display: inline-block;
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis; 

`
export const List = styled.button`
  background-color: #9AAAB2;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px; 
  cursor: pointer;
  display: inline-block;
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis; 
  margin-left: 10px
`


export const Block = styled.div`
  position: relative;
  width: 22.5%;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0px 8px 16px rgba(27, 31, 68, 0.04);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px; 
  @media (max-width: 768px) {
    width:100%
`;


export const TopLeftText = styled.div`
  display:flex;
  justify-content: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0px;
  text-align: left;
  white-space: pre-wrap;
  align-self: center;
`;

export const CenteredText = styled.div`
  font-size: 44px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  margin-top: auto; 
  cursor:pointer;
  text-align:center;
`;



export const SvgOverview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;

  svg {
    width: 100%;
    height: 40%;
  }
`;


export const OrangeText = styled.p`
  color: #FC6E45;
  font-size: 12px;
`


export const DataSquare = styled.p`
display:flex;
justify-content:space-between;
min-height: 285px;
flex-direction: column;
`

export const BHSRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction:column;
`;

export const ClientName = styled.div`
  flex: 1;
`;

export const BHSScore = styled.div``;

export const ScoreBar = styled.div`
  height: 10px;
  background-color: white;
  position: relative;
  width: 100%;
  border-radius:20px;
`;

export const FilledBar = styled.div`
  height: 100%;
  width: ${({ fillWidth }) => fillWidth || "0%"};
  background-color: #FC6E45;
  border-radius:20px;
`;


export const PerformanceNumbers = styled.div`
display: flex;
flex-direction: row; 
justify-content: space-between;
width: 100%;
@media (max-width: 768px) {
  flex-direction:column;
}

`

export const NotificationOverview = styled.div`
  display: flex;
  flex-grow: 1; 
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 20px 0;
  flex-wrap: wrap;
  max-width: 1366px;
  .churn-logo {
    height: 100px; 
    width: auto;  
  }
  @media (max-width: 768px) {
 justify-content:center;
}
@media (max-width: 1493px) {
  width:90%;
 }
 @media (max-width: 1536px) {
  width:90%;
  align-self:center;
 }
`;