import * as yup from "yup";

export const LoginValidation = yup.object().shape({
	username: yup
		.string()
		.min(2, "Length can't be lower than 2 characters")
		.max(30, "Length can't be more than 15 characters")
		.required("Headline can't be empty"),
	password: yup
		.string()
		.min(4, "Password must be at least 4 characters")
		.max(30, "Password can't be more than 30 characters")
		.required("Password is required"),

	// Confirm password can be used for later implementation

	// confirmPassword: yup
	// 	.string()
	// 	.oneOf([yup.ref("password"), null], "Passwords must match")
	// 	.required("Please confirm your password"),
});
