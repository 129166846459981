import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const useAppContext = () => {
    return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
    const [selectedBHSRange, setSelectedBHSRange] = useState([0, 100]);
    const [isActive, setIsActive] = useState("all");

    return (
        <AppContext.Provider value={{ selectedBHSRange, setSelectedBHSRange, isActive, setIsActive }}>
            {children}
        </AppContext.Provider>
    );
};
