import React, { useEffect } from "react";
import { CompanyInfo, Description } from "../Analytics/Analyticsempty.styled";
import { CardContainer, ProfitAndLossSvgStyle } from "./AnalyticsActive.styled";
import { pdfjs } from 'react-pdf';
import ClientsData from "../../data/ClientsData";
import { useParams } from "react-router";

const clients = ClientsData;

const ProfitAndLossCard = () => {
    const { id } = useParams();
    const client = clients.find((client) => client.id.toString() === id.trim());

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    const openPDFInNewTab = () => {
        // Replace 'your-pdf-file.pdf' with the actual path to your PDF file.
        window.open('../../pdf/ProfitandLoss.pdf', '_blank');
    };

    return (
        <CardContainer style={{ alignContent: "space-between" }}>
            <CompanyInfo>
                <div>
                    <div>
                        <h4>Profit and Loss</h4>
                        <Description>{client.name} profit since 01 Jan 2023 was:</Description>
                    </div>
                </div>
                <div>
                    <div style={{ color: client.pnlchange.startsWith('-') ? "#D8613F" : "#59b398" }}>{client.pnlchange}
                    </div>
                </div>
            </CompanyInfo>
            <div style={{ display: "block", clear: "both", width: "100%" }}>
                <p>${client.pnlamount}</p>
            </div>
            <ProfitAndLossSvgStyle onClick={openPDFInNewTab}>
                <a
                    href="../../pdf/ProfitandLoss.pdf"
                    target="_blank"
                    title="Open in New Tab"
                >
                    {client.pnl}
                </a>
            </ProfitAndLossSvgStyle>
        </CardContainer>
    );
}

export default ProfitAndLossCard;
