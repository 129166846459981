import ClientsData from "../data/ClientsData";

function useFilteredClients(searchQuery, filter, bhsRange) {
    const allClients = ClientsData;

    const filteredClients = allClients.filter((client) => {
        const nameIncludesQuery = client.name.toLowerCase().includes(searchQuery.toLowerCase());
        const industryIncludesQuery = client.industry.toLowerCase().includes(searchQuery.toLowerCase());

        if (nameIncludesQuery || industryIncludesQuery) {
            if (filter === "active" && client.status !== "ACTIVE") {
                return false;
            } else if (filter === "inactive" && client.status !== "INACTIVE") {
                return false;
            }

            const bhs = parseFloat(client.bhs);
            if (isNaN(bhs)) {
                return 0 >= bhsRange[0] && 0 <= bhsRange[1];
            } else {
                return bhs >= bhsRange[0] && bhs <= bhsRange[1];
            }
        }

        return false;
    });

    return filteredClients;
}

export default useFilteredClients;
