import React from 'react';
import { Description, SquareContainer } from '../Analytics/Analyticsempty.styled';
import { AccountsSquare, CardContainer } from './AnalyticsActive.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ClientsData from '../../data/ClientsData';
import { useParams } from 'react-router-dom';
import { AiFillStar } from 'react-icons/ai'


const clients = ClientsData;

const BankAccountItem = ({ account }) => (
    <AccountsSquare>
        <div>
            <Description style={{ fontSize: '16px' }}>{account.name}</Description>
            <Description style={{ marginBottom: '10px', fontSize: '14px' }}>Ends with {account.endingnumber}</Description>
        </div>
        <div style={{ width: "100%" }}>
            <Description>{account.accounttype}</Description>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <h4>{account.amount}</h4>
                {account.name === 'Community Bank of Minesota' && (
                    <AiFillStar style={{ color: "#FC6E45", fontSize: "20px" }} />
                )}
            </div>
        </div>
    </AccountsSquare>
);

const BankAccounts = ({ title, totalAccounts, totalBalance, accounts, handleBankVisibility, setIsFlipped }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                <h4>{title}</h4>
                <FontAwesomeIcon
                    onClick={() => {
                        setIsFlipped();
                        handleBankVisibility();
                    }}
                    icon={faArrowLeft}
                    style={{ cursor: 'pointer' }}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                <Description style={{ width: 'auto' }}>{totalAccounts} Accounts</Description>
                <Description style={{ width: 'auto' }}>Total Bank Balance</Description>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                <div>01-10-23</div>
                <div>{totalBalance}</div>
            </div>
            <SquareContainer>
                {accounts.map((account, index) => (
                    <BankAccountItem key={index} account={account} />
                ))}
            </SquareContainer>
        </div>
    );
};

const BankAccountList = ({ handleBankVisibility, isFlipped, setIsFlipped }) => {
    const { id } = useParams();
    const client = clients.find((c) => c.id === Number(id));

    return (
        <CardContainer className={isFlipped ? 'flipped' : ''} style={{ overflowY: "auto" }}>
            <BankAccounts
                title="Bank Accounts"
                totalAccounts={client.accounts.length}
                totalBalance={client.totalbalance}
                accounts={client.accounts}
                handleBankVisibility={handleBankVisibility}
                setIsFlipped={setIsFlipped}
            />
        </CardContainer>
    );
};

export default BankAccountList;
