import React from "react";
import { GrayButton, GreenButton, OrangeButton } from "../RequestedLoans/RequestedLoans.styled";
import { CFONotificationBlock, CardContainer, LoansSquare } from "./AnalyticsActive.styled";
import { CompanyInfo, Description } from "../Analytics/Analyticsempty.styled";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const LoanItem = ({ title, status, type, amount, period, rate, buttonColor }) => {
    const ButtonComponent = buttonColor === "green" ? GreenButton : buttonColor === "orange" ? OrangeButton : GrayButton;


    return (
        <LoansSquare>
            <div style={{ display: "flex", alignItems: "center", paddingTop: "15px", width: "90%" }}>
                <Description style={{ justifyContent: "space-between" }}>{title}</Description>
                <ButtonComponent style={{ marginTop: "0" }}>{status}</ButtonComponent>
            </div>
            <div style={{ display: "flex", alignItems: "center", paddingTop: "15px", width: "90%" }}>
                <Description style={{ justifyContent: "space-between" }}>{type}</Description>
                <Description style={{ justifyContent: "flex-end" }}>{amount}</Description>
            </div>
            <div style={{ display: "flex", alignItems: "center", width: "90%" }}>
                <Description style={{ justifyContent: "space-between", marginBottom: "10px" }}>{period}</Description>
                <Description style={{ justifyContent: "flex-end" }}>Rate from {rate}</Description>
            </div>
        </LoansSquare>
    );
};

const Loans = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    if (id === "5") {
        return (
            <CardContainer style={{ flexDirection: "row" }}>
                <h4>Loans</h4>
                <Description style={{ marginTop: "10px", justifyContent: "center", height: "100%", alignItems: "center" }}>No loan requests as of yet!</Description>
            </CardContainer>
        );
    }
    if (id === "6") {
        return (
            <CardContainer style={{ flexDirection: "column" }}>
                <CompanyInfo style={{ width: "100%", flexDirection: "column" }}>
                    <div style={{ justifyContent: "space-between", width: "100%" }}>
                        <h4>Loans</h4>
                        <p style={{ color: "#FC6E45", cursor: "pointer" }} onClick={() => navigate("/requestedloans")}>See all</p>
                    </div>
                    <Description style={{ marginTop: "10px", justifyContent: "flex-start" }}>Requested loans and their status</Description><br />
                </CompanyInfo>
                <CFONotificationBlock>
                    <LoanItem
                        title="Loan Request # 02"
                        status="Approved"
                        type="Mortgage"
                        amount="$700,000.00"
                        period="3 Years Period"
                        rate="16%"
                        buttonColor="green"
                    />
                </CFONotificationBlock>
            </CardContainer>
        );
    }
    return (
        <CardContainer style={{ flexDirection: "column" }}>
            <CompanyInfo style={{ width: "100%", flexDirection: "column" }}>
                <div style={{ justifyContent: "space-between", width: "100%" }}>
                    <h4>Loans</h4>
                    <p style={{ color: "#FC6E45", cursor: "pointer" }} onClick={() => navigate("/requestedloans")}>See all</p>
                </div>
                <Description style={{ marginTop: "10px", justifyContent: "flex-start" }}>Requested loans and their status</Description><br />
            </CompanyInfo>
            <CFONotificationBlock>
                <LoanItem
                    title="Loan Request # 28"
                    status="Pending"
                    type="Cash Loan"
                    amount="$400,000.00"
                    period="12 Months Period"
                    rate="16%"
                    buttonColor="gray"
                />
                <LoanItem
                    title="Loan Request # 27"
                    status="Approved"
                    type="Mortgage Loan"
                    amount="$1,500,000.00"
                    period="18 Months Period"
                    rate="8%"
                    buttonColor="green"
                />
                <LoanItem
                    title="Loan Request # 27"
                    status="Denied"
                    type="Debt Consolidation"
                    amount="$850,000.00"
                    period="16 Months Period"
                    rate="10%"
                    buttonColor="orange"
                />
            </CFONotificationBlock>
        </CardContainer>
    );
};

export default Loans;
