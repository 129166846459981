import { createSlice } from "@reduxjs/toolkit";
import WriteToLocalStorage from "../../../utils/WriteToLocalStorage";

const storageTheme = localStorage.geneQTY_theme;
const initialState = storageTheme ? storageTheme : "dark";

const themeSlice = createSlice({
	name: "theme",
	initialState,
	reducers: {
		changeTheme: (state, action) => {
			WriteToLocalStorage("geneQTY_theme", action.payload);
			return (state = action.payload);
		},
	},
});

export const themeSliceState = (state) => state.theme;
export const { changeTheme } = themeSlice.actions;

export default themeSlice.reducer;
