import React, { useEffect, useState } from 'react';
import { Block, Overview } from "../Home/Home.styled";
import { useNavigate } from 'react-router-dom';
import { TbCalendarDollar } from 'react-icons/tb';
import { LiaPiggyBankSolid } from 'react-icons/lia';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { GiChart } from 'react-icons/gi';
import { SlDiamond } from 'react-icons/sl'

const services = [
    { name: 'Loans', icon: TbCalendarDollar },
    { name: 'Savings', icon: LiaPiggyBankSolid },
    { name: 'Investments', icon: RiExchangeDollarLine },
    { name: 'Reward Programs', icon: SlDiamond },
    { name: 'Money Market Accounts', icon: GiChart },
];

const OtherServices = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const handleServiceClick = (service) => {
        if (service.name === 'Reward Programs') {
            navigate('/otherservices/rewardprograms');
        } else if (service.name === "Money Market Accounts") {
            navigate('/otherservices/moneymarketaccounts');
        }
        else {
            navigate(`/otherservices/${service.name.toLowerCase()}`);
        }
    };
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <Overview style={{ alignSelf: 'center', alignContent: 'flex-start', justifyContent: windowWidth <= 1536 ? 'flex-start' : '' }}>
            <h1>Other Services</h1>
            <hr style={{ width: '100%' }} />
            <Overview style={{ alignSelf: 'center', justifyContent: windowWidth <= 1536 ? 'flex-start' : '' }}>
                {services.map((service, index) => (
                    <Block
                        key={index}
                        style={{
                            height: '200px',
                            width: '320px',
                            margin: '10px 20px 10px 0px',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            fontSize: '24px',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleServiceClick(service)}
                    >
                        <div>{service.name}</div>
                        {service.icon && <service.icon color={'#FF6448'} size={80} />}
                    </Block>
                ))}
            </Overview>
        </Overview>
    );
};

export default OtherServices;
