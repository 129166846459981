import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function useSliderRange(initialValue) {
    const { bhsRangeParam } = useParams();
    const initialRange = bhsRangeParam ? bhsRangeParam.split("-").map(Number) : initialValue;
    const [range, setRange] = useState(initialRange);

    useEffect(() => {
        if (bhsRangeParam) {
            const newRange = bhsRangeParam.split("-").map(Number);
            setRange(newRange);
        }
    }, [bhsRangeParam]);

    return [range, setRange];
}

export default useSliderRange;