import { Block, Overview } from "../../Home/Home.styled";
import { InvestmensData } from "../../../data/OtherServicesData";
import InvestmentsListTable from "./InvestmentsListTable";

export const Investments = () => {

    const loanTitleStyle = {
        fontSize: "24px",
        marginBottom: "20px",
    };

    const investments = [
        {
            title: 'Stocks',
            period: 'Rate of return at 20%',
            interestRate: 'Higher risk',
            maxLoanAmount: 'Weekly stock market report'
        },
        {
            title: 'Bonds',
            period: 'Rate of return at 12%',
            interestRate: 'Low risk',
            maxLoanAmount: 'Monthly bonds market reports'
        },
    ]
    return (
        <Overview style={{ flexDirection: 'column', alignSelf: 'center' }}>
            <div style={{ width: '100%' }}>
                <h2>Investments</h2>
                <hr />
                <p>Here is the list of available investment offers</p>
                <Overview style={{ width: '100%', paddingBottom: '10px', justifyContent: 'flex-start' }}>
                    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex' }}>
                        <div style={{ display: 'inline-block', minWidth: '300px', margin: '10px' }}>
                            <Block style={{ minWidth: '300px', height: '160px', justifyContent: 'center', textAlign: 'center', color: '#FC6E45', fontSize: '100px', cursor: 'pointer' }} >+</Block>
                        </div>
                        {investments.map((investments, index) => (
                            <div key={index} style={{ display: 'inline-block', margin: '10px' }}>
                                <Block style={{ minWidth: '300px' }}>
                                    <p style={loanTitleStyle}>{investments.title}</p>
                                    <ul style={{ paddingLeft: '20px' }}>
                                        <li>{investments.period}</li>
                                        <li>{investments.interestRate}</li>
                                        <li>{investments.maxLoanAmount}</li>
                                    </ul>
                                </Block>
                            </div>
                        ))}
                    </div>
                </Overview>
                <hr />
                <div style={{ marginBottom: '10px' }}>
                    <h2>Investments List</h2>
                    <p>Here is the list of requested and offered investments</p>
                </div>
                <InvestmentsListTable investments={InvestmensData} />
            </div>
        </Overview>
    )
}

export default Investments