import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addUser } from "../../store/features/userSlice/userSlice";
import { LoginContainer, LoginForm } from "./Login.styled";
import { LoginButton } from "../../components/Button/Button.styled";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginValidation } from "../../validations/LoginValidation";
import BankLogo from "../../components/LeftSideBar/LeftSideBarSvg";
import InputField from "./InputField";

const Login = () => {
	const dispatch = useDispatch();
	const logIn = (username) => dispatch(addUser(username));

	const { handleSubmit, control } = useForm({
		resolver: yupResolver(LoginValidation),
	});
	const [isBadCredentials, setIsBadCredentials] = useState(false);

	const onSubmit = (data) => {
		const username = data.username.toLowerCase();
		if (username === "admin@geneqty.com" && data.password === "AdminGE!0976") {
			logIn(username);
		} else {
			setIsBadCredentials(true);
		}
	};

	return (
		<LoginContainer>
			<div>
				<BankLogo />
			</div>
			<LoginForm onSubmit={handleSubmit(onSubmit)}>
				<InputField
					control={control}
					name="username"
					type="text"
					placeholder="Please enter your username"
					label="Email"
				/>
				<InputField
					control={control}
					name="password"
					type="password"
					placeholder="Please enter a password"
					label="Password"
				/>
				<LoginButton>LOG IN</LoginButton>
			</LoginForm>
			{isBadCredentials && (
				<div style={{ textAlign: 'center', color: '#FF6448' }}>
					<p style={{ fontSize: '24px' }}>Incorrect credentials!</p>
					<p>Invalid username or password</p>
				</div>
			)
			}
		</LoginContainer>
	);
};

export default Login;
