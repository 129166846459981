import React from "react";
import { ReportsSquare, TwoReportSquare } from "./Reports.styled";
import { useState } from "react";
import { CardContainer } from "../AnalyticsActive1/AnalyticsActive.styled";

const industryNames = [
    "Competitive Analysis",
    "Technology & Innovation",
    "Risk Assessment",
    "Industry Performance",
    "Market Share Report",
    "Government Reports",
];

const IndustryReportNames = ({ customStyles, useTwoReportSquare, setIndustryName, isIndustry }) => {
    const [activeIndex, setActiveIndex] = useState(isIndustry);

    const handleClick = (industryName) => {
        setActiveIndex(industryName);
        setIndustryName(industryName);
    };

    const ReportSquareComponent = useTwoReportSquare ? TwoReportSquare : ReportsSquare;

    return (
        <CardContainer style={customStyles}>
            {industryNames.map((industryName, index) => (
                <ReportSquareComponent
                    key={index}
                    clicked={activeIndex === industryName}
                    onClick={() => handleClick(industryName)}
                >
                    {industryName}
                </ReportSquareComponent>
            ))}
        </CardContainer>
    )

}
export default IndustryReportNames;