import styled from "styled-components";


export const ReportsContainer = styled.div`
display:flex;
padding: 20px;
border-radius: 20px;
background-color: #242D41;
flex-direction: row; 
flex-wrap: wrap;
overflow-y:auto;
margin-right:30px;

`

export const ReportsSquareContainer = styled.div`
border-radius: 20px;
background-color: #242D41;
display: flex;
flex-direction: row; 
flex-wrap: wrap;
overflow-y:auto;
`

export const SelectMenu = styled.select`
display:flex;
align-items:center;
background-color: #353D50;
border-radius: 20px;
color: white;
width: 100%;
height: 35px;
padding-left: 20px;
`


export const ReportsSquare = styled.div`
  flex-basis: calc(33.33% - 10px);
  background-color: #343d50;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  height: 130px;
  place-content: center;
  text-align: center;
  color: ${({ clicked }) => (clicked ? '#FC6E45' : '#9AAAB2')};
  cursor: pointer;
  border: 2px solid transparent;
  border: ${({ clicked }) => (clicked ? '2px solid #FC6E45' : '2px solid transparent')};
  div:first-child {
    width: 100%;
  }
  div:nth-child(2) {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    min-width: 95%;
  }
`;

export const SeeReport = styled.button`
  background-color: #FF6448;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 40px; 
  cursor: pointer;
  width: 30%;
  align-self:center;
`

export const ReportsOverview = styled.div`
display: flex;
flex-grow: 1; 
justify-content: space-between;
align-items: center;
padding: 20px 0 20px 0;
width: 1366px;
@media (max-width: 1593px) {
    flex-wrap: wrap;
    width: 90%;
  }
@media (max-width: 768px) {
justify-content:center;
}
`

export const TwoReportSquare = styled(ReportsSquare)`
flex-basis: calc(50% - 10px); 

`


export const SearchbarContainer = styled.div`
  position: relative;  
`;

export const SearchbarInput = styled.input`
width: 255px;
height: 46px;
padding: 4px 20px;
background: #232D40;
border-radius: 20px;
border: 1.5px solid #FFFFFF;
color: #FFFFFF;
&.selected {
  border-color: #white;
}
&::placeholder {
  color: #FFFFFF;
}
`;

export const SearchResults = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #353d50;
  border-radius: 20px;
  color: white;
  z-index: 1;
`;

export const NoResults = styled.div`
  padding: 10px;
`;

export const MatchedResult = styled.div`
  cursor: pointer;
  font-size: 20px;
  padding: 8px 16px;
  transition: background-color 0.3s; 

  &:hover {
    background-color: #2c3242;
  }
`;