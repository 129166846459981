export const IndustryData = [
    {
        id: 1,
        name: "Accounting and Bookkeeping"
    },
    {
        id: 2,
        name: "Advertising and Public Relations"
    },
    {
        id: 3,
        name: "Agriculture"
    },
    {
        id: 4,
        name: "Automotive Sale"
    },
    {
        id: 5,
        name: "Consulting Services"
    },
    {
        id: 6,
        name: "Construction Trades such as Plumbers, Electricians, HVAC"
    },
    {
        id: 7,
        name: "Design, Architecture, and Engineering"
    },
    {
        id: 8,
        name: "Ecommerce"
    },
    {
        id: 9,
        name: "Hair Salons, Beauty Salons, and Barber Shops"
    },
    {
        id: 10,
        name: "Internet Services"
    },
    {
        id: 11,
        name: "Information Technology"
    },
    {
        id: 12,
        name: "Legal Services"
    },
    {
        id: 13,
        name: "Manufacturing - Food"
    },
    {
        id: 14,
        name: "Manufacturing - Beverage"
    },
    {
        id: 15,
        name: "Medical and Healthcare"
    },
    {
        id: 16,
        name: "Packaging"
    },
    {
        id: 17,
        name: "Restaurants and Bars"
    },
    {
        id: 18,
        name: "Retail Clothing"
    },
    {
        id: 19,
        name: "Staffing Services"
    },
    {
        id: 20,
        name: "Store - General"
    },
    {
        id: 21,
        name: "Transport"
    },
    {
        id: 22,
        name: "Travel Services"
    },
    {
        id: 23,
        name: "Wholesale Distribution"
    },
    {
        id: 24,
        name: "Healthcare"
    },
];

export default IndustryData;