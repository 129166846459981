import { Overview } from "../Home/Home.styled";
import NotificationsTable from "./NotificationsTable";
import Notifications from "../../data/NotificationsData";

const CfoNotifications = () => {


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Overview style={{ justifyContent: 'center' }}>
                <div style={{ alignSelf: 'center', width: '100%' }} >
                    <div style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <h1>CFO Notifications</h1>
                        <hr style={{ width: '100%', marginBottom: '20px' }} />
                        <h4>Here is the list of Recent CFO Notifications</h4>
                    </div>
                    <NotificationsTable notifications={Notifications} />
                </div>
            </Overview>
        </div>
    );
};

export default CfoNotifications;
