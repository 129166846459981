import React, { useState, useEffect } from "react";
import { Table, SortIcon, TableContainer, TableData } from "../../ClientsTable/Table.styled";
import { FilterButtonContainer, FilterDropdown } from "../../Clients/Clients.styled";
import { useNavigate } from "react-router-dom";

const LoansListTable = ({ loans, state }) => {
    const [sortField, setSortField] = useState("loantype");
    const [sortOrder, setSortOrder] = useState("asc");
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(state);
    const [selectedType, setSelectedType] = useState("");
    const [clientOptions, setClientOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const navigate = useNavigate();
    console.log(selectedStatus)
    useEffect(() => {
        const uniqueClients = [...new Set(loans.map(loan => loan.client))];
        const uniqueStatuses = [...new Set(loans.map(loan => loan.status))];
        const uniqueTypes = [...new Set(loans.map(loan => loan.loantype))];

        setClientOptions(uniqueClients);
        setStatusOptions(uniqueStatuses);
        setTypeOptions(uniqueTypes);
    }, [loans]);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };
    const filteredLoans = loans.filter((loan) => {
        const clientMatch = !selectedClient || loan.client === selectedClient;
        const statusMatch = !selectedStatus || loan.status === selectedStatus;
        const typeMatch = !selectedType || loan.loantype === selectedType;
        return clientMatch && statusMatch && typeMatch;
    });

    const sortedLoans = [...filteredLoans].sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (aValue === bValue) {
            return 0;
        }

        if (sortOrder === "asc") {
            if (aValue === "-" || aValue === undefined) {
                return 1;
            } else if (bValue === "-" || bValue === undefined) {
                return -1;
            } else {
                return aValue < bValue ? -1 : 1;
            }
        } else {
            if (aValue === "-" || aValue === undefined) {
                return -1;
            } else if (bValue === "-" || bValue === undefined) {
                return 1;
            } else {
                return bValue < aValue ? -1 : 1;
            }
        }
    });

    return (
        <TableContainer style={{ margin: '0' }}>
            <FilterButtonContainer style={{ justifyContent: 'center', paddingBottom: '20px' }}>
                <p>Show:</p>
                <div>
                    <FilterDropdown
                        value={selectedClient}
                        onChange={(e) => setSelectedClient(e.target.value)}
                    >
                        <option value="">Client</option>
                        {clientOptions.map((client, index) => (
                            <option key={index} value={client}>
                                {client}
                            </option>
                        ))}
                    </FilterDropdown>
                </div>
                <div>
                    <FilterDropdown
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                    >
                        <option value="">Type</option>
                        {typeOptions.map((type, index) => (
                            <option key={index} value={type}>
                                {type}
                            </option>
                        ))}
                    </FilterDropdown>
                </div>
                <div>
                    <FilterDropdown
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="">Status</option>
                        {statusOptions.map((status, index) => (
                            <option key={index} value={status}>
                                {status}
                            </option>
                        ))}
                    </FilterDropdown>
                </div>
            </FilterButtonContainer>
            <Table>
                <thead>
                    <tr>
                        <th onClick={() => handleSort("client")}>
                            Client <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("loantype")}>
                            Loan Type <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("status")}>
                            Status <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th>
                            Action <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedLoans.map((loan) => (
                        <tr key={loan.id}>
                            <TableData>{loan.client}</TableData>
                            <TableData>
                                {loan.loantype.toUpperCase()}
                            </TableData>
                            <TableData>
                                {loan.status.toUpperCase()}
                            </TableData>
                            <TableData style={{ color: "#FC6E45", cursor: 'pointer' }} onClick={() => navigate('/clientloandetails')}>SEE DETAILS</TableData>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </TableContainer>
    );
};

export default LoansListTable;
