import { useState } from "react";

const usePopupStates = () => {
    const [popupStates, setPopupStates] = useState({});

    const handleInviteClick = (client) => {
        setPopupStates((prevPopupStates) => ({
            ...prevPopupStates,
            [client.id]: true,
        }));

        setTimeout(() => {
            setPopupStates((prevPopupStates) => ({
                ...prevPopupStates,
                [client.id]: false,
            }));
        }, 3000);
    };

    return { popupStates, handleInviteClick };
};

export default usePopupStates;
