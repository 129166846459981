import { Overview } from "../../Home/Home.styled";
import {
    CenteredButton,
    CustomInput,
    InputContainer,
    InputFields,
    Label
} from "../../Clients/Clients.styled";

const NewRewardProgram = ({ handleCreate }) => {
    const inputFields = [
        "Reward Program Name",
        "Type",
        "Applied To",
        "Program Duration",
        "Redeeming Rewards",
        "Interest Rate",
        "Fees",
        "Bonus Perks"
    ];

    return (
        <Overview style={{ flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
            <h1 style={{ paddingBottom: '20px' }}>Create New Reward Program</h1>
            <InputContainer style={{ paddingBottom: '40px' }}>
                {inputFields.map((field, index) => (
                    <InputFields key={index}>
                        <Label>{field}:</Label>
                        <CustomInput type="text" />
                    </InputFields>
                ))}
            </InputContainer>
            <CenteredButton onClick={handleCreate}>Create</CenteredButton>
        </Overview>
    );
}

export default NewRewardProgram;
