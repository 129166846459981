import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
   * {
	// outline: 1px solid red;
      margin:0;
      padding:0;
      box-sizing:border-box;
      font-family:'Poppins', sans-serif;
   }
   body {
      color: ${(props) => props.theme.text};
      background-color: ${(props) => props.theme.background}
   }
   ::-webkit-scrollbar {
	width: 5px;
	height:7px;
  }
  
  ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
	background: #fc6e45;
	border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
	background: #fc6e45;
  }

   @keyframes hopInFromBottom {
	0% {
		transform: translateY(200px);
		opacity: 0;
	}
	50% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
`;

export default GlobalStyles;
