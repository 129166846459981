import React, { useState } from "react";
import { GoldCard, SilverCard } from "../../../svg/LoansSvg";
import { CardContainer, HorisontalLine } from "../../AnalyticsActive1/AnalyticsActive.styled";
import { Overview } from "../../Home/Home.styled";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { BiSolidPlaneAlt } from 'react-icons/bi'
import { LiaPiggyBankSolid } from 'react-icons/lia'
import { ToggleButtonContainer } from '../OtherServices.Styled'

const RewardsDetails = () => {
    const [isEnabled1, setIsEnabled1] = useState(false);
    const [isEnabled2, setIsEnabled2] = useState(false);
    const [isEnabled3, setIsEnabled3] = useState(false);

    const toggleButton1 = () => {
        setIsEnabled1(!isEnabled1);
    };

    const toggleButton2 = () => {
        setIsEnabled2(!isEnabled2);
    };

    const toggleButton3 = () => {
        setIsEnabled3(!isEnabled3);
    };

    return (
        <div style={{ alignSelf: 'center' }}>
            <HorisontalLine />
            <Overview style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <h1>Business Reward Program</h1>
                <h4>Joberty LLC</h4>
                <p>Here are the Businesss Rewards Cards Joberty LLC possesses.</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <GoldCard />
                    <SilverCard />
                </div>
            </Overview>
            <HorisontalLine />
            <Overview style={{ flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                <h4>Rewards Program</h4>
                <p>List of available Programs. Here you can expand or restrict a program.</p>
                <CardContainer style={{ flexDirection: 'row', marginLeft: '0', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex' }}>
                        <AiOutlineDollarCircle style={{ color: '#FC6E45', width: '62px', height: '62px', marginRight: '15px' }} />
                        <div style={{ width: '80%' }}>
                            <p>Redeem to Account</p>
                            <p>Redeeming rewards as a credit to a qualifying credit card, checking account or mortgage.</p>
                        </div>
                    </div>
                    <div>
                        <ToggleButtonContainer className={isEnabled1 ? 'enabled' : 'disabled'} onClick={toggleButton1}>
                            <div className="circle"></div>
                        </ToggleButtonContainer>
                        <p style={{ color: '#FC6E45' }}>{isEnabled1 ? 'Disabled' : 'Enabled'}</p>
                    </div>
                </CardContainer>
                <CardContainer style={{ flexDirection: 'row', marginLeft: '0', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex' }}>
                        <BiSolidPlaneAlt style={{ color: '#FC6E45', width: '62px', height: '62px', marginRight: '15px' }} />
                        <div style={{ width: '80%' }}>
                            <p>Explore Travel</p>
                            <p>Redeemng rewards for flights, car rentals and hotel stays.</p>
                        </div>
                    </div>
                    <div>
                        <ToggleButtonContainer className={isEnabled2 ? 'enabled' : 'disabled'} onClick={toggleButton2}>
                            <div className="circle"></div>
                        </ToggleButtonContainer>
                        <p style={{ color: '#FC6E45' }}>{isEnabled2 ? 'Disabled' : 'Enabled'}</p>
                    </div>
                </CardContainer>
                <CardContainer style={{ flexDirection: 'row', marginLeft: '0', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', width: '90%' }}>
                        <LiaPiggyBankSolid style={{ color: '#FC6E45', width: '62px', height: '62px', marginRight: '15px' }} />
                        <div style={{ width: '80%' }}>
                            <p>Redeem for Purchases</p>
                            <p>Redeeming rewards for purchases, in form of a statement credit, to cover eligible purchases already made.</p>
                        </div>
                    </div>
                    <div>
                        <ToggleButtonContainer className={isEnabled3 ? 'enabled' : 'disabled'} onClick={toggleButton3}>
                            <div className="circle"></div>
                        </ToggleButtonContainer>
                        <p style={{ color: '#FC6E45' }}>{isEnabled3 ? 'Disabled' : 'Enabled'}</p>
                    </div>
                </CardContainer>
            </Overview>
        </div>
    )
}

export default RewardsDetails;
