import React, { useState } from "react";
import CompanyInfoCard from "../AnalyticsActive1/CompanyInfoCard";
import ClientReportNames from "./ClientsReportNames";
import { ReportsOverview } from "./Reports.styled";
import { CardContainer } from "../AnalyticsActive1/AnalyticsActive.styled";
import GenericSearchBar from "./GenericSearchBar";
import ClientsData from "../../data/ClientsData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SquareContainer } from "../Analytics/Analyticsempty.styled";
import { Overview } from "../Home/Home.styled";
import { useReportContext } from "./ReportContext";
import { BipBalanceSheetSecondReport } from "../../svg/ReportsSvg";

const clients = ClientsData;

const ClientReports = () => {
    const location = useLocation();
    const { state } = location;
    const { id } = useParams();
    const activeClients = clients.filter((client) => client.status === "ACTIVE");
    const client = activeClients.find((client) => client.id.toString() === id.trim());
    const { selectedReportType, setSelectedReportType } = useReportContext();

    // Set an initial value for isReport based on state
    const [isReport, setIsReport] = useState(state ? state.isReport : '');
    const navigate = useNavigate();

    const handleClientSelect = (clientName) => {
        const selectedClient = activeClients.find((client) => client.name === clientName);

        if (selectedClient) {
            setSelectedReportType(selectedReportType);
            navigate(`/reports/${selectedClient.id}/clients`, { state: { isReport } });
        }
    };

    const openProfitAndLoss = () => {
        window.open("../../pdf/ProfitandLoss.pdf", "_blank");
    };
    const openBalanceSheet = () => {
        window.open("../../pdf/BalanceSheet.pdf", "_blank");
    };
    const openCashFlow = () => {
        window.open("../../pdf/StatementofCashFlows.pdf", "_blank");
    };
    return (
        <div style={{ alignSelf: "center" }}>
            <ReportsOverview style={{ alignItems: "flex-start", flexDirection: "column" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <h1>Client Reports</h1>
                    <GenericSearchBar
                        data={activeClients}
                        onSelect={handleClientSelect}
                        placeholder="Search for clients"
                    />
                </div>
                <hr style={{ width: '100%' }} />
            </ReportsOverview>
            <div>
                <ReportsOverview>
                    <CompanyInfoCard
                        customStyles={{
                            justifyContent: "space-evenly",
                            minHeight: "475px",
                            flexDirection: "column",
                        }}
                        isAccounts
                    />
                    <ClientReportNames
                        customStyles={{ minHeight: "475px", alignContent: "center" }}
                        useTwoReportSquare={true}
                        setReportName={setIsReport}
                        isReport={state.isReport}
                    />

                    <CardContainer style={{ justifyContent: "center", minHeight: "475px", alignContent: "center" }}>
                        {client.kpigraphs.cashratiograph}
                        {client.kpigraphs.netprofitmargingraph}
                        {client.kpigraphs.debttoratiograph}
                    </CardContainer>
                </ReportsOverview>
                <Overview style={{ justifyContent: "space-around" }}>
                    <SquareContainer style={{ justifyContent: "center" }}>
                        {isReport === 'Profit and Loss' && (
                            <div onClick={openProfitAndLoss}>
                                {client.pnlreport}
                            </div>
                        )}
                        {isReport === 'Balance Sheet' && (
                            <div onClick={openBalanceSheet} style={{ textAlign: 'center' }}>
                                {client.balancesheetreport}
                                <BipBalanceSheetSecondReport />
                            </div>
                        )}
                        {isReport === 'Expense Allocation' && (
                            <div>
                                {client.expenseallocationreport}
                            </div>
                        )}
                        {isReport === 'Cash Flow' && (
                            <div onClick={openCashFlow}>
                                {client.cashflowreport}
                            </div>
                        )}
                    </SquareContainer>
                </Overview>
            </div>
        </div>
    );
};

export default ClientReports;
