import styled from "styled-components";

export const Circle = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`;

export const Initial = styled.span`
    font-size: 40px;
    font-weight: bold;
    color: #000000;
`;

export const Description = styled.div`
    display:flex;
    color: #9AAAB2;
    font-family: poppins;
    width: 100%;
`;

export const SquareContainer = styled.div`
  display: flex;
  flex-direction: row; 
  width: 100%;
  flex-wrap: wrap;
  overflow-y:auto;  
  `;



export const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  & > div:first-child {
    display: flex;
    align-items: center;
  }

  & > :nth-child(2) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const EmptyCompanyInfo = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: flex-start;
`
export const DivideBlocks = styled.div`
display:flex;
flex-direction: row;
margin-left: 5%;
margin-right: 5%;
`
export const AnalyticsBlock = styled.div`
  position: relative;
  width: 45%;
  height: 150px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  padding: 15px; 
`

export const AnalyticsCards = styled.div`
display: flex;
flex-direction: column;
margin-left: 10%;
padding: 20px;
width:40%;
`

export const AnalyticsTopLeftText = styled.div`
color: #9AAAB2;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 120%; 
`

export const AnalyticsBottomText = styled.div`
font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 26px;
letter-spacing: 0px;
text-align: left;
`
export const LeftGroupCards = styled.div`
display: flex;
    flex-direction: column;
`
export const RightGroupCards = styled.div`
display: flex;
    flex-direction: column;
    align-items:flex-start;
    padding-left:11%
`
export const AlignCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 10px;

  & > ${AnalyticsBlock} {
    margin-bottom: 20px; 
  }
`;

export const SecondDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 16px;
`;

export const H2Margin = styled.h2`
margin-left: 15px;
`
export const DescriptionMargin = styled.div`
    color: #9AAAB2;
    font-family: poppins;
    margin-left:15px;
`;



export const SvgContainers = styled.div`
background-color: #232E40;
border-radius: 20px;
width: 360px;
display: flex;
justify-content: center;
height: 300px;
align-items:center;
`