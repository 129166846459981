
export const EmotionAccounts = [
    {
        total: '$503,282.65',
        name: 'Community Bank of Minesota',
        endingnumber: '4643',
        accounttype: 'Checking Account',
        amount: '$342,961.57'
    },
    {
        total: '$503,282.65',
        name: 'Bank of America',
        endingnumber: '9584',
        accounttype: 'Savings Account',
        amount: '$38,405.81'
    },
    {
        total: '$503,282.65',
        name: 'Cititbank',
        endingnumber: '3374',
        accounttype: 'Checking Account',
        amount: '$117,568.05'
    },
    {
        total: '$503,282.65',
        name: 'Community Bank of Minesota',
        endingnumber: '4643',
        accounttype: 'Checking Account',
        amount: '$4,347.22'
    }
];

export const ElixirAccounts = [
    {
        total: '$84,753.42',
        name: 'Community Bank of Minesota',
        endingnumber: '3748',
        accounttype: 'Checking Account',
        amount: '$14,522.64'
    },
    {
        total: '$84,753.42',
        name: 'Bank of America',
        endingnumber: '2046',
        accounttype: 'Savings Account',
        amount: '$27,703.13'
    },
    {
        total: '$84,753.42',
        name: 'Bank of America',
        endingnumber: '7785',
        accounttype: 'Checking Account',
        amount: '$42,527.61'
    }
];

export const BipAccounts = [
    {
        total: '$1,303,608.12',
        name: "Bank of America",
        endingnumber: "4587",
        accounttype: "Checking Account",
        amount: "$367,824.86"
    },
    {
        total: '$1,303,608.12',
        name: "Bank of America",
        endingnumber: "3711",
        accounttype: "Checking Account",
        amount: "$127,307.18"
    },
    {
        total: '$1,303,608.12',
        name: "Citibank",
        endingnumber: "8080",
        accounttype: "CD Account",
        amount: "$54,367.07"
    },
    {
        total: '$1,303,608.12',
        name: "Wells Fargo",
        endingnumber: "6601",
        accounttype: "Money Market Account",
        amount: "$14,522.64"
    }
];
