import { CardContainer } from "../AnalyticsActive1/AnalyticsActive.styled";
import { NotificationOverview } from "../Home/Home.styled"
import { AiOutlineWarning } from "react-icons/ai"
import { FaSackDollar } from "react-icons/fa6"
import { BiSolidUserPlus } from 'react-icons/bi'
import { WhiteButton } from '../RequestedLoans/RequestedLoans.styled'

const LiquidityRatio = () => {
    return (
        <div style={{ display: 'flex' }} >
            <NotificationOverview>
                <div>
                    <div style={{ display: 'flex', justifyContent: "space-evenly", flexDirection: 'column', width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}><h1>Liquidity Ratio below optimal</h1><AiOutlineWarning style={{ fontSize: '32px', color: "#FC6E45" }} /></div>
                        <hr />
                        <div style={{ paddingBottom: '15px' }}>Jaffa’s Quick liquidity ratio is below the critical value.</div>
                        <div style={{ paddingBottom: '15px' }}>Currently, Jaffa’s Quick liquidity ratio is 0.71, which is way below the industry standard (2.00). This indicates the company’s liquid assets are not sufficient to cover the short-term liabilities without selling the inventory. Company may struggle to meet the immediate financial obligations and might face difficulty paying the suppliers, servicing debt or unexpected expenses </div>
                        <div style={{ paddingBottom: '15px' }}>Below is the list of suggested actions that might help out Jaffa in increasing the Quick Liquidity ratio.</div>
                    </div>
                </div>
                <div>
                    <h2>Suggested Actions</h2>
                    <CardContainer style={{ flexDirection: "row", flexWrap: "nowrap", height: "auto", marginLeft: "0px" }}>
                        <FaSackDollar style={{ width: '80px', height: '80px', color: "#FC6E45", margin: "30px" }} />
                        <div>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignContent: 'space-around', marginBottom: '10px' }}><h2>Short-term Loan</h2></div>
                            <div>Short-term Loan can ensure the additional liquidity for the company. It involves repaying the principle amount with interest within a year from getting the loan. Based on the given company information, the most suitable short-term loan in this case would be Line of Credit. </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-end', height: '130px' }}>
                            <WhiteButton style={{ margin: '0 30px 0 0' }}>Offer</WhiteButton>
                        </div>
                    </CardContainer>
                    <CardContainer style={{ flexDirection: "row", flexWrap: "nowrap", height: "auto", marginLeft: "0px" }}>
                        <BiSolidUserPlus style={{ width: '80px', height: '80px', color: "#FC6E45", margin: "30px" }} />
                        <div>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignContent: 'space-around', marginBottom: '10px' }}><h2>Financial Advisory Service</h2></div>
                            <div>Financial Advisory Service can include: budgeting, cash flow analysis and financial planning. Combined, these services can help the company to better manage its resources and allocate funds towards retaining customers effectively. </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-end', height: '130px' }}>
                            <WhiteButton style={{ margin: '0 30px 0 0' }}>Offer</WhiteButton>
                        </div>
                    </CardContainer>
                </div>
            </NotificationOverview>
        </div>
    )
}
export default LiquidityRatio