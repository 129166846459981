import { CompanyInfo, Description } from "../Analytics/Analyticsempty.styled";
import { CardContainer, CashFlowSvgStyle } from "./AnalyticsActive.styled";
import ClientsData from "../../data/ClientsData";
import { useParams } from "react-router-dom";
const clients = ClientsData



const CashFlowPopupCard = () => {
    const { id } = useParams();
    const client = clients.find((client) => client.id.toString() === id.trim());

    return (
        <>
            <CardContainer style={{ alignContent: "space-between" }}>
                <CompanyInfo>
                    <div>
                        <div>
                            <h4>Net Cash</h4>
                        </div>
                    </div>
                    <div>
                        <div style={{ color: "#59b398" }}>
                        </div>
                    </div>
                </CompanyInfo>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <h3>{client.netcash}</h3>
                </div>
                <CompanyInfo>
                    <Description>May 23 - Oct 23</Description>
                    <Description>As of 11:10 AM</Description>
                </CompanyInfo>
                <CashFlowSvgStyle>
                    {client.netcashgraph}
                </CashFlowSvgStyle>
            </CardContainer>
            <CardContainer style={{ alignContent: "space-between" }}>
                <CompanyInfo>
                    <div>
                        <div>
                            <h4>Cash In vs. Cash Out</h4>
                        </div>
                    </div>
                    <div>
                        <div style={{ color: "#59b398" }}>
                        </div>
                    </div>
                </CompanyInfo>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <h3>{client.cashinout}</h3>
                </div>
                <CompanyInfo>
                    <Description>Cash In / Cash out</Description>
                    <Description>Upd Oct 1, 2023</Description>
                </CompanyInfo>
                <CashFlowSvgStyle>
                    {client.cashinoutgraph}
                </CashFlowSvgStyle>
            </CardContainer>
        </>
    )
}
export default CashFlowPopupCard;