import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReportsOverview } from "./Reports.styled";
import { CardContainer } from "../AnalyticsActive1/AnalyticsActive.styled";
import { Description } from "../Analytics/Analyticsempty.styled";
import { CustomerDistribution, IndustryPerformanceSvg, IndustryReportsProfitMarginSvg, IndustryReportsProfitSvg, IndustryReportsRevenueSvg, ProductsSegmentation, RevenueGrowthProjection, SalesByRegion, TopCompanies } from "../../svg/IndustryReportSvg";
import IndustryReportNames from "./IndustryReportNames";
import GenericSearchBar from "./GenericSearchBar";
import IndustryData from "../../data/IndustryData"




const IndustryReports = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const { id } = useParams();
    const [isIndustryName, setIsIndustryName] = useState(state ? state.isIndustry : '')
    const currentIndustry = IndustryData.find((industry) => industry.id === Number(id));
    const handleIndustrySelect = (industryName) => {
        const selectedIndustry = IndustryData.find((industry) => industry.name === industryName);
        if (selectedIndustry) {
            navigate(`/reports/${selectedIndustry.id}/industry`, { state: { isIndustryName } });
        }
    };
    const ReportCard = ({ title, value, growthData, graph }) => (
        <CardContainer style={{ alignContent: "stretch", justifyContent: "center", height: '460px' }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <h2>{title}</h2>
                <h3>{value}</h3>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", textAlign: "center" }}>
                {growthData.map(({ label, growth }) => (
                    <div key={label}>
                        <div>
                            Annual Growth<br /> {label}
                        </div>
                        <h4>{growth}</h4>
                    </div>
                ))}
            </div>
            <div style={{ alignContent: "center" }}>{graph}</div>
        </CardContainer>
    );

    return (
        <>
            <ReportsOverview style={{ alignItems: "flex-start", flexWrap: 'wrap', alignSelf: 'center', flexDirection: 'column' }}>
                <h1>Industry Reports</h1>
                <hr style={{ width: '100%', marginBottom: '20px' }} />
                <div style={{ display: "flex", justifyContent: "space-between", fontSize: "30px", alignItems: "center", width: "100%" }}>
                    <div>{currentIndustry.name}
                    </div>
                    <div>
                        <GenericSearchBar
                            data={IndustryData}
                            onSelect={handleIndustrySelect}
                            placeholder="Search for industries"
                        />
                    </div>
                </div>
            </ReportsOverview>
            <ReportsOverview style={{ flexWrap: 'wrap', alignSelf: 'center' }}>
                <CardContainer style={{ height: "460px", flexDirection: "column", justifyContent: "space-evenly" }}>
                    <div>
                        <h2>Top 3 Companies</h2>
                    </div>
                    <TopCompanies />
                </CardContainer>
                <IndustryReportNames
                    customStyles={{ height: "460px", alignContent: "center" }}
                    useTwoReportSquare={true}
                    setIndustryName={setIsIndustryName}
                    isIndustry={state.isIndustry}
                />
                {isIndustryName !== 'Market Share Report' && (
                    <CardContainer style={{ height: "460px", flexDirection: "column", justifyContent: "space-evenly", flexWrap: 'nowrap', paddingTop: '70px' }}>
                        <div>
                            <div>
                                <h1>Industry Performance</h1>
                                <Description>Total profit 2017 - 2023</Description>
                            </div>
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <IndustryPerformanceSvg />
                            </div>
                        </div>
                    </CardContainer>
                )}
                {isIndustryName === 'Market Share Report' && (
                    <CardContainer style={{ height: "460px" }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <div style={{ textAlign: 'start' }}>
                                <p style={{ fontSize: '20px' }}>Customer Distribution</p>
                                <p style={{ marginBottom: '50px', color: '#9AAAB2' }}>In 2022</p>
                                <CustomerDistribution />
                            </div>
                        </div>
                    </CardContainer>
                )}
                {isIndustryName !== 'Market Share Report' && (
                    <ReportCard
                        title="Revenue"
                        value="$997bn"
                        growthData={[
                            { label: "2010-2015", growth: "-4%" },
                            { label: "2016-2020", growth: "23%" },
                            { label: "2020-2023", growth: "11%" },
                        ]}
                        graph=<IndustryReportsRevenueSvg />
                    />
                )}
                {isIndustryName === 'Market Share Report' && (
                    <CardContainer style={{ height: '460px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <div style={{ textAlign: 'start' }}>
                                <p style={{ fontSize: '20px' }}>Sales by Region</p>
                                <p style={{ marginBottom: '50px', color: '#9AAAB2' }}>2020 - 2023</p>
                                <SalesByRegion />
                            </div>
                        </div>
                    </CardContainer>
                )}
                {isIndustryName !== 'Market Share Report' && (
                    <ReportCard
                        title="Profit"
                        value="$682bn"
                        growthData={[
                            { label: "2010-2015", growth: "2%" },
                            { label: "2016-2020", growth: "16%" },
                            { label: "2020-2023", growth: "9%" },
                        ]}
                        graph=<IndustryReportsProfitSvg />
                    />
                )}
                {isIndustryName === 'Market Share Report' && (
                    <CardContainer style={{ height: '460px' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <div style={{ textAlign: 'start' }}>
                                <p style={{ fontSize: '20px' }}>Products Segmentation</p>
                                <p style={{ marginBottom: '50px', color: '#9AAAB2' }}>In 2022</p>
                            </div>
                            <ProductsSegmentation />
                        </div>
                    </CardContainer>
                )}
                {isIndustryName !== 'Market Share Report' && (
                    <ReportCard
                        title="Profit Margin"
                        value="22%"
                        growthData={[
                            { label: "2010-2015", growth: "-1.2 pp" },
                            { label: "2016-2020", growth: "4 pp" },
                            { label: "2020-2023", growth: "1.6 pp" },
                        ]}
                        graph=<IndustryReportsProfitMarginSvg />
                    />
                )}
                {isIndustryName === 'Market Share Report' && (
                    <CardContainer style={{ height: '460px' }}>
                        <p style={{ fontSize: '20px', marginBottom: '50px' }}>Revenue and Growth Projection by Category</p>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <RevenueGrowthProjection />
                        </div>
                    </CardContainer>
                )}
            </ReportsOverview>
        </>
    )
}
export default IndustryReports;