import React, { useState, useEffect } from "react";
import {
    Table,
    SortIcon,
    TableContainer,
    TableData,
} from "../ClientsTable/Table.styled";
import { FilterButtonContainer, FilterDropdown } from "../Clients/Clients.styled";
import { useNavigate } from "react-router-dom";
import { useChurnRate } from './ChurnRateContext ';


const NotificationsTable = ({ notifications }) => {
    const [sortField, setSortField] = useState("date");
    const [sortOrder, setSortOrder] = useState("asc");
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedSubject, setSelectedSubject] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [clientOptions, setClientOptions] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const { setIsChurnRate } = useChurnRate();


    const navigate = useNavigate();

    // Extract unique client names, subjects, and statuses
    useEffect(() => {
        const uniqueClients = [...new Set(notifications.map(notification => notification.client))];
        const uniqueSubjects = [...new Set(notifications.map(notification => notification.subject))];
        const uniqueStatuses = [...new Set(notifications.map(notification => notification.status))];
        setClientOptions(uniqueClients);
        setSubjectOptions(uniqueSubjects);
        setStatusOptions(uniqueStatuses);
    }, [notifications]);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };


    const filteredNotifications = notifications.filter((notification) => {
        const clientMatch = !selectedClient || notification.client === selectedClient;
        const subjectMatch = !selectedSubject || notification.subject === selectedSubject;
        const statusMatch = !selectedStatus || notification.status === selectedStatus;
        return clientMatch && subjectMatch && statusMatch;
    });

    const sortedNotifications = [...filteredNotifications].sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (aValue === bValue) {
            return 0;
        }

        if (sortOrder === "asc") {
            if (aValue === "-" || aValue === undefined) {
                return 1;
            } else if (bValue === "-" || bValue === undefined) {
                return -1;
            } else {
                return aValue < bValue ? -1 : 1;
            }
        } else {
            if (aValue === "-" || aValue === undefined) {
                return -1;
            } else if (bValue === "-" || bValue === undefined) {
                return 1;
            } else {
                return bValue < aValue ? -1 : 1;
            }
        }
    });

    return (
        <TableContainer>
            <FilterButtonContainer style={{ justifyContent: 'center', paddingBottom: '20px' }}>
                <p>Show:</p>
                <div>
                    <FilterDropdown
                        value={selectedClient}
                        onChange={(e) => setSelectedClient(e.target.value)}
                    >
                        <option value="" >Client</option>
                        {clientOptions.map((client, index) => (
                            <option key={index} value={client} >
                                {client}
                            </option>
                        ))}
                    </FilterDropdown>
                </div>
                <div>
                    <FilterDropdown
                        value={selectedSubject}
                        onChange={(e) => setSelectedSubject(e.target.value)}
                    >
                        <option value="">Subject</option>
                        {subjectOptions.map((subject, index) => (
                            <option key={index} value={subject}>
                                {subject}
                            </option>
                        ))}
                    </FilterDropdown>
                </div>
                <div>
                    <FilterDropdown
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="">Status</option>
                        {statusOptions.map((status, index) => (
                            <option key={index} value={status}>
                                {status}
                            </option>
                        ))}
                    </FilterDropdown>
                </div>
            </FilterButtonContainer>
            <Table>
                <thead>
                    <tr>
                        <th onClick={() => handleSort("subject")}>
                            Notification <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("client")}>
                            Client <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("subject")}>
                            Subject <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("date")}>
                            Date <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("status")}>
                            Status <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedNotifications.map((notification) => (
                        <tr key={notification.id}>
                            <TableData>
                                {notification.notification}
                            </TableData>
                            <TableData>{notification.client}</TableData>
                            <TableData>{notification.subject}</TableData>
                            <TableData>{notification.date}</TableData>
                            <TableData
                                style={{
                                    color: notification.status === "ACTIVE" ? "#FC6E45" : "inherit",
                                    cursor: notification.status === "ACTIVE" ? "pointer" : "inherit"
                                }}
                                onClick={() => {
                                    if (notification.status === 'ACTIVE') {
                                        navigate(`/cfo/${notification.id}/notification`);
                                        if (notification.subject === 'Liquidity Ratio') {
                                            setIsChurnRate(false);
                                        } else if (notification.subject === 'Churn Rate') {
                                            setIsChurnRate(true);
                                        }
                                    }
                                }}
                            >
                                {notification.status === "ACTIVE" ? "ACTION REQUIRED" : "NO ACTION"}
                            </TableData>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </TableContainer >
    );
};

export default NotificationsTable;
