import React, { useState } from "react";
import { ReportsSquare, TwoReportSquare } from "./Reports.styled";
import { CardContainer } from "../AnalyticsActive1/AnalyticsActive.styled";

const reportNames = [
    "Cash Flow",
    "Balance Sheet",
    "Profit and Loss",
    "Expense Allocation",
    "Accounts Receivable & Accounts Payable",
    "Statement of Changes in Equity",
];

const ClientReportNames = ({
    customStyles,
    useTwoReportSquare,
    setReportName,
    isReport,
}) => {
    const [activeIndex, setActiveIndex] = useState(isReport);

    const handleClick = (reportName) => {
        setActiveIndex(reportName);
        setReportName(reportName);
    };

    const ReportSquareComponent = useTwoReportSquare
        ? TwoReportSquare
        : ReportsSquare;

    return (
        <CardContainer style={customStyles}>
            {reportNames.map((reportName, index) => (
                <ReportSquareComponent
                    key={index}
                    clicked={activeIndex === reportName}
                    onClick={() => handleClick(reportName)}
                >
                    {reportName}
                </ReportSquareComponent>
            ))}
        </CardContainer>
    );
};

export default ClientReportNames;
