import React, { useState } from "react";
import {
    Description,
    CompanyInfo,
    SquareContainer,
} from "../Analytics/Analyticsempty.styled";
import { Square, KpiRedFont, InLineDescription, KpiGreenFont, KpiOrangeFont } from "./AnalyticsActive.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar, faChartColumn, faCoins, faEye, faCircleInfo, faArrowLeft, faExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import ClientsData from "../../data/ClientsData";
import { useParams } from "react-router-dom";
const clients = ClientsData;


const KpiIndicators = () => {
    const [isDebtRatioVisible, setIsDebtRatioVisible] = useState(false);
    const [isProfitabilityRatioVisible, setIsProfitabilityRatioVisible] = useState(false);
    const [isLiquidityRatioVisible, setIsLiquidityRatioVisible] = useState(false);
    const { id } = useParams();
    const client = clients.find((client) => client.id.toString() === id.trim());

    const handleDebtRatio = () => {
        setIsDebtRatioVisible(true);
        setIsProfitabilityRatioVisible(false);
        setIsLiquidityRatioVisible(false);
    }

    const handleProfitabilityRatio = () => {
        setIsDebtRatioVisible(false);
        setIsProfitabilityRatioVisible(true);
        setIsLiquidityRatioVisible(false);
    }

    const handleIsLiquidityRatio = () => {
        setIsDebtRatioVisible(false);
        setIsProfitabilityRatioVisible(false);
        setIsLiquidityRatioVisible(true);
    }
    const handleResetState = () => {
        setIsDebtRatioVisible(false);
        setIsProfitabilityRatioVisible(false);
        setIsLiquidityRatioVisible(false);
    }

    const SquareWithTooltip = ({ title, content, value, fontColor, icon, fontClass }) => {
        const KpiFontClass = fontClass === 'green' ? KpiGreenFont : KpiOrangeFont;

        return (
            <Square>
                <InLineDescription>
                    <Description>{title}</Description>
                    <CustomTooltip id={title.toLowerCase().replace(/ /g, '-')} content={content} />
                </InLineDescription>
                <div>
                    <KpiFontClass>{value}</KpiFontClass>
                    {icon && <KpiFontClass className={fontClass}>{icon}</KpiFontClass>}
                </div>
            </Square>
        );
    }
    const CustomTooltip = ({ id, content }) => {
        return (
            <>
                <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{ color: "#f75a4f", cursor: "pointer" }}
                    data-tooltip-id={id}
                    data-tooltip-html={content}
                    data-tooltip-place="right-end"
                />
                <ReactTooltip
                    id={id}
                    effect="solid"
                    arrowColor="transparent"
                    tipPointerPosition='Start'
                    style={{
                        borderRadius: "20px",
                        backgroundColor: "#9AAAB2",
                        width: "300px",
                        color: "black"
                    }}
                />
            </>
        );
    };

    let arrow = <FontAwesomeIcon onClick={handleResetState} icon={faArrowLeft} style={{ cursor: "pointer" }} />
    let h4Text = 'Key Performance Indicators';
    let description = "KPIs evaluate company’s success based on its financial performance"
    if (isDebtRatioVisible) {
        h4Text = 'Debt Ratio';
        description = 'Indicate the portion of assets company has financed through debt.'
    } else if (isProfitabilityRatioVisible) {
        h4Text = 'Profitability Ratio';
        description = 'Indicate how efficiently company generates profit and value for the shareholders.'
    } else if (isLiquidityRatioVisible) {
        h4Text = 'Liquidity Ratio';
        description = "Indicate the ability to pay debt obligations and company's safety margin"
    }
    return (
        <>
            <CompanyInfo style={{ width: "100%", flexDirection: "column" }}>
                <div style={{ justifyContent: "space-between", width: "100%" }}>
                    <h4>{h4Text}</h4>
                    {(isDebtRatioVisible || isProfitabilityRatioVisible || isLiquidityRatioVisible) && (
                        <p>{arrow}</p>
                    )}
                </div>
                <Description style={{ marginTop: "10px" }}>{description}</Description>
            </CompanyInfo>
            <SquareContainer style={{ maxHeight: "280px" }}>
                {isDebtRatioVisible && (
                    <>
                        <SquareWithTooltip
                            title="Debt-to-Equity"
                            content={'<div style="font-weight: bold;">Debt-to-equity</div>' +
                                (client.kpi.debtToEquity < client.kpiaverage.debttoequity
                                    ? 'Good! Debt-to-Equity ratio is within industry average.'
                                    : 'Caution! Debt-to-Equity is above the industry average.')
                                + '<br />Good Debt-to-Equity Ratio in ' + client.industry + ' industry is considered to be below ' + client.kpiaverage.debttoequity + '.'}
                            value={client.kpi.debtToEquity}
                            fontClass={client.kpi.debtToEquity < client.kpiaverage.debttoequity ? 'green' : 'orange'}
                            icon={
                                client.kpi.debtToEquity < client.kpiaverage.debttoequity ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )
                            }
                        />

                        <SquareWithTooltip
                            title="Debt Ratio"
                            content={
                                '<div style="font-weight: bold;">Debt Ratio</div>' +
                                (client.kpi.debtratio < client.kpiaverage.debtratio
                                    ? 'Good! Debt ratio is within average.'
                                    : 'Caution! Debt Ratio is above the average.')
                                +
                                '<br />Good Debt Ratio is considered to be below ' + client.kpiaverage.debtratio + ', applied to all industries.'}
                            value={client.kpi.debtratio}
                            fontClass={client.kpi.debtratio < client.kpiaverage.debtratio ? 'green' : 'orange'}
                            icon={
                                client.kpi.debtratio < client.kpiaverage.debtratio ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )
                            }
                        />
                    </>
                )
                }
                {
                    isLiquidityRatioVisible && (
                        <>
                            <SquareWithTooltip
                                title="Current Ratio"
                                content={
                                    '<div style="font-weight: bold;">Current Ratio</div>' +
                                    (client.kpi.currentratio > client.kpiaverage.currentratio
                                        ? "Amazing! Current Ratio is above the average."
                                        : "Caution! Current ratio is below the average.")
                                    + '<br />Good Current Ratio in ' + client.industry + ' industry is considered to be above ' + client.kpiaverage.currentratio + ', applied to all industries.'}
                                value={client.kpi.currentratio}
                                fontClass={client.kpi.currentratio > client.kpiaverage.currentratio ? 'green' : 'orange'}
                                icon={
                                    client.kpi.currentratio > client.kpiaverage.currentratio ? (
                                        <FontAwesomeIcon icon={faCircleCheck} />
                                    ) : (
                                        <FontAwesomeIcon icon={faExclamation} />
                                    )
                                }
                            />

                            <SquareWithTooltip
                                title="Quick Ratio"
                                content={'<div style="font-weight: bold;">Quick Ratio</div>' +
                                    (client.kpi.quickratio > client.kpiaverage.quickratio
                                        ? "Good! Quick Ratio is above the industry average."
                                        : "Caution! Quick Ratio is below the average.")
                                    + '<br />Good Quick Ratio in ' + client.industry + ' industry is considered to be above ' + client.kpiaverage.quickratio + '.'}
                                value={client.kpi.quickratio}
                                fontClass={client.kpi.quickratio > client.kpiaverage.quickratio ? 'green' : 'orange'}
                                icon={client.kpi.quickratio > client.kpiaverage.quickratio ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )}
                            />


                            <SquareWithTooltip
                                title="Cash Ratio"
                                content={'<div style="font-weight: bold;">Cash Ratio</div>' +
                                    (client.kpi.cashratio > client.kpiaverage.cashratio
                                        ? "Amazing! Cash Ratio is above the average."
                                        : "Your Cash Ratio is below the average.")
                                    + '<br />Good Cash Ratio is considered to be above ' + client.kpiaverage.cashratio + ', applied to all industries.'}
                                value={client.kpi.cashratio}
                                fontClass={client.kpi.cashratio > client.kpiaverage.cashratio ? 'green' : 'orange'}
                                icon={client.kpi.cashratio > client.kpiaverage.cashratio ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )}
                            />
                        </>
                    )
                }
                {
                    isProfitabilityRatioVisible && (
                        <>
                            <SquareWithTooltip
                                title="Operating Profit Margin Ratio"
                                content={'<div style="font-weight: bold;">Operating Profit Margin Ratio</div>' +
                                    (client.kpi.operatingprofitmargin > client.kpiaverage.operatingprofitmargin
                                        ? "Amazing! Operating Profit Margin is above the average."
                                        : "Caution! Operating Profit Margin is below the average.")
                                    + '<br />Good Operating Profit Margin in ' + client.industry + ' industry is considered to be above ' + client.kpiaverage.operatingprofitmargin + '%.'}
                                value={client.kpi.operatingprofitmargin + '%'}
                                fontClass={client.kpi.operatingprofitmargin > client.kpiaverage.operatingprofitmargin ? 'green' : 'orange'}
                                icon={client.kpi.operatingprofitmargin > client.kpiaverage.operatingprofitmargin ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )}
                            />
                            <SquareWithTooltip
                                title="Net Profit Margin"
                                content={'<div style="font-weight: bold;">Net Profit Margin</div>' +
                                    (client.kpi.netprofitmargin > client.kpiaverage.netprofitpargin
                                        ? "Good! Net Profit Margin is within industry average."
                                        : "Caution! Net Profit Margin is below the industry average.")
                                    + '<br />Good Net Profit Margin in ' + client.industry + ' industry is considered to be above ' + client.kpiaverage.netprofitpargin + '%.'}
                                value={client.kpi.netprofitmargin + '%'}
                                fontClass={client.kpi.netprofitmargin > client.kpiaverage.netprofitpargin ? 'green' : 'orange'}
                                icon={client.kpi.netprofitmargin > client.kpiaverage.netprofitpargin ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )}
                            />
                            <SquareWithTooltip
                                title="Return on Asset"
                                content={'<div style="font-weight: bold;">Return on Asset</div>' +
                                    (client.kpi.returnonasset > client.kpiaverage.returnonassets
                                        ? "Good! Return on Assets is within industry average."
                                        : "Caution! Return on Assets is below the industry average.")
                                    + '<br />Good Return on Assets in ' + client.industry + ' industry is considered to be above ' + client.kpiaverage.returnonassets + '%.'}
                                value={client.kpi.returnonasset + '%'}
                                fontClass={client.kpi.returnonasset > client.kpiaverage.returnonassets ? 'green' : 'orange'}
                                icon={client.kpi.returnonasset > client.kpiaverage.returnonassets ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )}
                            />
                            <SquareWithTooltip
                                title="Return on Equity"
                                content={'<div style="font-weight: bold;">Return on Equity</div>' +
                                    (client.kpi.returnonequity > client.kpiaverage.returnonequity
                                        ? "Good! Return on Equity is within the industry average."
                                        : "Caution! Return on Equity is below the industry average.")
                                    + '<br />Good Return on Equity in ' + client.industry + ' industry is considered to be above ' + client.kpiaverage.returnonequity + '%.'}
                                value={client.kpi.returnonequity + '%'}
                                fontClass={client.kpi.returnonequity > client.kpiaverage.returnonequity ? 'green' : 'orange'}
                                icon={client.kpi.returnonequity > client.kpiaverage.returnonequity ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )}
                            />
                            <SquareWithTooltip
                                title="Inventory Turnover"
                                content={'<div style="font-weight: bold;">Inventory Turnover</div>' +
                                    (client.kpi.inventoryturnover < client.kpiaverage.inventoryturnover
                                        ? "Amazing! Inventory Turnover is above the average."
                                        : "Caution! Inventory Turnover is below the average.")
                                    + '<br />Good Inventory Turnover is considered to be below ' + client.kpiaverage.inventoryturnover + ', applied to all industries.'}
                                value={client.kpi.inventoryturnover}
                                fontClass={client.kpi.inventoryturnover < client.kpiaverage.inventoryturnover ? 'green' : 'orange'}
                                icon={client.kpi.inventoryturnover < client.kpiaverage.inventoryturnover ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )}
                            />
                            <SquareWithTooltip
                                title="Gross Profit Margin"
                                content={'<div style="font-weight: bold;">Gross Profit Margin</div>' +
                                    (client.kpi.grossprofitmargin > client.kpiaverage.grossprofitmargin
                                        ? "Caution! Gross profit Margin is below the industry average."
                                        : "Amazing! Gross Profit Margin is above the industry average.")
                                    + '<br />Good Gross Profit Margin in ' + client.industry + ' industry is considered to be above ' + client.kpiaverage.grossprofitmargin + '%.'}
                                value={client.kpi.grossprofitmargin + '%'}
                                fontClass={client.kpi.grossprofitmargin > client.kpiaverage.grossprofitmargin ? 'green' : 'orange'}
                                icon={client.kpi.grossprofitmargin > client.kpiaverage.grossprofitmargin ? (
                                    <FontAwesomeIcon icon={faCircleCheck} />
                                ) : (
                                    <FontAwesomeIcon icon={faExclamation} />
                                )}
                            />
                        </>
                    )
                }
                {
                    !isLiquidityRatioVisible && !isProfitabilityRatioVisible && !isDebtRatioVisible && (
                        <>
                            <Square>
                                <InLineDescription>
                                    <Description>Liqudity Ratios</Description>
                                    <FontAwesomeIcon onClick={handleIsLiquidityRatio} icon={faEye} style={{ color: "#f75a4f", cursor: "pointer" }} />
                                </InLineDescription>
                                <div>
                                    <KpiRedFont><FontAwesomeIcon icon={faHandHoldingDollar} /></KpiRedFont>
                                </div>
                            </Square>
                            <Square>
                                <InLineDescription>
                                    <Description>Profitability Ratios</Description>
                                    <FontAwesomeIcon onClick={handleProfitabilityRatio} icon={faEye} style={{ color: "#f75a4f", cursor: "pointer" }} />
                                </InLineDescription>
                                <div>
                                    <KpiRedFont><FontAwesomeIcon icon={faChartColumn} /></KpiRedFont>
                                </div>
                            </Square>
                            <Square>
                                <InLineDescription>
                                    <Description>Debt Ratios</Description>
                                    <FontAwesomeIcon onClick={handleDebtRatio} icon={faEye} style={{ color: "#f75a4f", cursor: "pointer" }} />
                                </InLineDescription>
                                <div>
                                    <KpiRedFont><FontAwesomeIcon icon={faCoins} /></KpiRedFont>
                                </div>
                            </Square>
                        </>
                    )
                }
            </SquareContainer >
        </>
    )
}
export default KpiIndicators;