import React from "react";
import { Table, SortIcon, TableContainer, TableData } from "../../ClientsTable/Table.styled";
import { FilterButtonContainer, FilterDropdown } from "../../Clients/Clients.styled";
import { useNavigate } from "react-router-dom";
import { useCustomTable } from "../../../hooks/useCustomTable";

const RewardsListTable = ({ rewards }) => {
    const {
        selectedClient,
        setSelectedClient,
        selectedStatus,
        setSelectedStatus,
        selectedProgram,
        setSelectedProgram,
        clientOptions,
        statusOptions,
        programOptions,
        handleSort,
        sortedItems,
    } = useCustomTable(rewards);
    const navigate = useNavigate();

    return (
        <TableContainer style={{ margin: '0', width: '100%' }}>
            <FilterButtonContainer style={{ justifyContent: 'center', paddingBottom: '20px' }}>
                <p>Show:</p>
                <div>
                    <FilterDropdown
                        value={selectedClient}
                        onChange={(e) => setSelectedClient(e.target.value)}
                    >
                        <option value="">Client</option>
                        {clientOptions.map((client, index) => (
                            <option key={index} value={client}>
                                {client}
                            </option>
                        ))}
                    </FilterDropdown>
                </div>
                <div>
                    <FilterDropdown
                        value={selectedProgram}
                        onChange={(e) => setSelectedProgram(e.target.value)}
                    >
                        <option value="">Program</option>
                        {programOptions.map((program, index) => (
                            <option key={index} value={program}>
                                {program}
                            </option>
                        ))}
                    </FilterDropdown>
                </div>
                <div>
                    <FilterDropdown
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="">Status</option>
                        {statusOptions.map((status, index) => (
                            <option key={index} value={status}>
                                {status}
                            </option>
                        ))}
                    </FilterDropdown>
                </div>
            </FilterButtonContainer>
            <Table>
                <thead>
                    <tr>
                        <th onClick={() => handleSort("client")}>
                            Client <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("program")}>
                            Program <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                        <th onClick={() => handleSort("status")}>
                            Status <SortIcon>&#8593;</SortIcon><SortIcon>&#8595;</SortIcon>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedItems.map((reward) => (
                        <tr key={reward.id}>
                            <TableData>{reward.client}</TableData>
                            <TableData>
                                {reward.program}
                            </TableData>
                            <TableData>
                                {reward.status}
                            </TableData>
                            <TableData style={{ color: "#FC6E45", cursor: 'pointer' }} onClick={() => navigate('/otherservices/rewarddetails')}>See details</TableData>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </TableContainer>
    );
};

export default RewardsListTable;
