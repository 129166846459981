import styled from "styled-components";

export const ToggleButtonContainer = styled.div`
  width: 50px;
  height: 25px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 20px;

  .circle {
    width: 25px;
    height: 25px;
    background-color: #FC6E45;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  p {
    transform: translateX(0);
    transition: transform 0.3s ease;
  }

  &.enabled .circle {
    transform: translateX(25px);
  }

  &.disabled p {
    transform: translateX(-45px);
  }
`;