import React from "react";
import { useController } from "react-hook-form";
import {
	InputFieldContainer,
	InputFieldLabel,
	InputFieldError,
} from "./Login.styled";
import { Input } from "../../components/Input/Input.styled";

const InputField = (props) => {
	const { field, fieldState } = useController(props);
	const { type, label, placeholder } = props;
	const errorMessage = fieldState.error?.message;

	return (
		<InputFieldContainer>
			<InputFieldLabel>{label || "Default Label"}</InputFieldLabel>
			<Input
				type={type || "text"}
				min={type === "number" ? 0 : ""}
				// hasError={!!errorMessage}
				onBlur={field.onBlur}
				onChange={field.onChange}
				name={field.name}
				defaultValue={field.value}
				placeholder={placeholder || "Default placeholder"}
				autoComplete="off"
			/>
			<InputFieldError>{errorMessage}</InputFieldError>
		</InputFieldContainer>
	);
};

export default InputField;
