import React, { useState } from 'react';
import { MatchedResult, NoResults, SearchResults, SearchbarContainer, SearchbarInput } from './Reports.styled';

function GenericSearchBar({ data, onSelect, placeholder }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleInputChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        if (value.trim() === '') {
            setSearchResults([]);
        } else {
            const matchingItems = data.filter((item) =>
                item.name.toLowerCase().includes(value)
            );
            setSearchResults(matchingItems);
        }
    };

    const handleResultClick = (itemName) => {
        setSearchTerm('');
        setSearchResults([]);
        onSelect(itemName);
    };

    return (
        <SearchbarContainer>
            <SearchbarInput
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleInputChange}
            />
            {searchResults.length > 0 && (
                <SearchResults>
                    <ul>
                        {searchResults.map((item) => (
                            <MatchedResult
                                key={item.id}
                                onClick={() => handleResultClick(item.name)}
                            >
                                <MatchedResult
                                    style={{ fontSize: '20px', cursor: 'pointer' }}
                                >
                                    {item.name}
                                </MatchedResult>
                            </MatchedResult>
                        ))}
                    </ul>
                </SearchResults>
            )}
            {searchResults.length === 0 && searchTerm.trim() !== '' && (
                <SearchResults>
                    <NoResults>No matching items found</NoResults>
                </SearchResults>
            )}
        </SearchbarContainer>
    );
}

export default GenericSearchBar;
