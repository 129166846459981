import { KpiCashRatioBipGraph, KpiCashRatioElixirGraph, KpiCashRatioEmotionGraph, KpiDebtToEquityBipGraph, KpiDebtToEquityElixirGraph, KpiDebtToEquityEmotionGraph, KpiNetProfitMarginBipGraph, KpiNetProfitMarginElixirGraph, KpiNetProfitMarginEmotionGraph } from "../svg/ReportsSvg";

export const EmotionKpiGraphs = {
    cashratiograph: <KpiCashRatioEmotionGraph />,
    netprofitmargingraph: <KpiNetProfitMarginEmotionGraph />,
    debttoratiograph: <KpiDebtToEquityEmotionGraph />
}

export const ElixirKpiGraphs = {
    cashratiograph: <KpiCashRatioElixirGraph />,
    netprofitmargingraph: <KpiNetProfitMarginElixirGraph />,
    debttoratiograph: <KpiDebtToEquityElixirGraph />
}

export const BipKpiGraphs = {
    cashratiograph: <KpiCashRatioBipGraph />,
    netprofitmargingraph: <KpiNetProfitMarginBipGraph />,
    debttoratiograph: <KpiDebtToEquityBipGraph />
}