import React from "react";
import { Navigate } from "react-router-dom";
import paths from "../../routes/paths";
import useCountdown from "../../hooks/useCountdown";

const NotFound = () => {
	const { count } = useCountdown(false, 5);

	if (count < 1) return <Navigate to={paths.home} />;
	return (
		<div>
			<div>Page Not Found</div>
			<div>You will be redirected to home in {count} seconds</div>
		</div>
	);
};

export default NotFound;
