const Notifications = [
    {
        id: 1,
        subject: 'Churn Rate',
        notification: 'Churn Rate very high',
        client: 'Joberty LLC',
        date: '10-02-2023',
        status: 'ACTIVE',
    },
    {
        id: 2,
        subject: 'Liquidity Ratio',
        notification: 'Liquidity Ratio below optimal value',
        client: 'Jaffa',
        date: '10-02-2023',
        status: 'ACTIVE',
    },
    {
        id: 3,
        subject: 'Sales Trend',
        notification: 'Sales Trend 11% lower',
        client: 'La Fontana',
        date: '10-01-2023',
        status: 'ACTIVE',
    },
    {
        id: 4,
        subject: 'Debt Ratio',
        notification: 'Debt Ratio Decrease',
        client: 'Forma Ideale',
        date: '09-29-2023',
        status: 'INACTIVE',
    },
    {
        id: 5,
        subject: 'Net Pf. Margin',
        notification: 'Net profit margin increase',
        client: 'BIP',
        date: '09-27-2023',
        status: 'INACTIVE',
    },
    {
        id: 6,
        subject: 'Profitability',
        notification: 'Cash ratio increase by 2 pp',
        client: 'Hemofarm',
        date: '09-26-2023',
        status: 'INACTIVE',
    },
    {
        id: 7,
        subject: 'Liquidity Ratio',
        notification: 'Liquidity Ratio below optimal',
        client: 'La Fontana',
        date: '09-26-2023',
        status: 'ACTIVE',
    },
    {
        id: 8,
        subject: 'BHS',
        notification: 'BHS decrease',
        client: 'Forma Ideale',
        date: '09-25-2023',
        status: 'INACTIVE',
    },
    {
        id: 9,
        subject: 'Gross Pf. Margin',
        notification: 'Gross Profit Margin increase',
        client: 'Emotion production',
        date: '09-24-2023',
        status: 'INACTIVE',
    },
    {
        id: 10,
        subject: 'Debt Ratio',
        notification: 'Debt Ratio increase',
        client: 'Lukoil',
        date: '09-24-2023',
        status: 'INACTIVE',
    },
];

export default Notifications;
