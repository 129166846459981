import React, { useState } from "react";
import {
    Overview,
    Block,
} from "../../Home/Home.styled";
import LoansListTable from "./LoansListTable";
import NewLoanOffer from "./NewLoanOffer";
import LoansData from '../../../data/OtherServicesData';
import { useLocation } from "react-router-dom";

const ServiceLoans = () => {
    const [isCreate, setIsCreate] = useState(false);
    const [newLoanData, setNewLoanData] = useState([]);
    const location = useLocation();
    const { state } = location;

    const handleCreate = () => {
        setIsCreate(!isCreate);
    };

    const loanTitleStyle = {
        fontSize: "24px",
        marginBottom: "20px",
    };

    const loanTypes = [
        {
            title: "Small Business Loan",
            period: "1",
            interestRate: "11",
            maxLoanAmount: "800,000.00",
        },
        {
            title: "Debt Consolidation",
            period: "3",
            interestRate: "4",
            maxLoanAmount: "1,500,000.00",
        },
        {
            title: "Credit Builder",
            period: "5",
            interestRate: "16",
            maxLoanAmount: "1,000,000.00",
        },
        {
            title: "Mortgage",
            period: "30",
            interestRate: "8%",
            maxLoanAmount: "2,000,000.00",
        },
    ];

    const allLoanData = [...loanTypes, ...newLoanData];

    return (
        <>
            {!isCreate && (
                <Overview style={{ flexDirection: 'column', alignSelf: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <h2>Loans</h2>
                        <hr />
                        <p>Here is the list of available Loan Offers</p>
                        <Overview style={{ width: '100%', paddingBottom: '10px' }}>
                            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex' }}>
                                <div style={{ display: 'inline-block', minWidth: '300px', margin: '10px' }}>
                                    <Block style={{ minWidth: '300px', height: '160px', justifyContent: 'center', textAlign: 'center', color: '#FC6E45', fontSize: '100px', cursor: 'pointer' }} onClick={handleCreate}>+</Block>
                                </div>
                                {allLoanData.map((loanType, index) => (
                                    <div key={index} style={{ display: 'inline-block', margin: '10px' }}>
                                        <Block style={{ minWidth: '300px' }}>
                                            <p style={loanTitleStyle}>{loanType.title}</p>
                                            <ul style={{ paddingLeft: '20px' }}>
                                                <li>{loanType.period} Year Period</li>
                                                <li>Fixed Interest rate at {loanType.interestRate}%</li>
                                                <li>Max. loan amount ${loanType.maxLoanAmount}</li>
                                            </ul>
                                        </Block>
                                    </div>
                                ))}
                            </div>
                        </Overview>
                        <hr />
                        <div style={{ marginBottom: '10px' }}>
                            <h2>Loans List</h2>
                            <p>Here is the list of requested and offered loans</p>
                        </div>
                        <LoansListTable loans={LoansData} state={state} />
                    </div>
                </Overview>
            )}
            <Overview style={{ height: '100%', width: '100%', alignSelf: 'center' }}>
                {isCreate && <NewLoanOffer handleCreate={handleCreate} setNewLoanData={setNewLoanData} />}
            </Overview>
        </>
    );
};

export default ServiceLoans;
