import styled from "styled-components";

export const LoanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px; /* Adjust as needed */
  padding: 10px; /* Adjust as needed */
`;

export const VerticalLine = styled.div`
  border-left: 2px solid #666; /* Gray vertical line */
  margin: 0 10px; /* Adjust as needed */
  color: yellow;
`;

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const LoanDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15px; 
  width:100%;
  height: 100px;
  align-items:center;
  align-content:center;
`;