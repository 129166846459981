import React, { useState } from "react";
import { Overview } from "../Home/Home.styled";
import {
    Description,
    CompanyInfo,
} from "../Analytics/Analyticsempty.styled";
import { CardContainer, CashFlowSvgStyle } from "./AnalyticsActive.styled";
import ClientsData from "../../data/ClientsData";
import { useParams } from "react-router-dom";
import Loans from "./Loans";
import RecentCfoNotifications from "./RecentCfoNotifications";
import ProfitAndLossCard from "./ProfitAndLossCard";
import CompanyInfoCard from "./CompanyInfoCard";
import CashFlowPopupCard from "./CashFlowPopupCard";
import KpiIndicators from "./KpiIndicators";
import BankAccountList from "./BankAccounts";
const clients = ClientsData;

const AnalyticsActive = () => {
    const { id } = useParams();
    const [isCashFlowPopupVisible, setIsCashFlowPopupVisible] = useState(false);
    const [isBankAccountsVisible, setIsBankAccountsVisible] = useState(false);
    const [isFlipped, setIsFlipped] = useState(true);


    const toggleCashFlowPopup = () => {
        setIsCashFlowPopupVisible(!isCashFlowPopupVisible);
    };
    const toggleIsFlipped = () => {
        setIsFlipped(!isFlipped)
    }
    const client = clients.find((client) => client.id.toString() === id.trim());

    if (!client) {
        return <div>Loading...</div>;
    }
    return (
        <Overview style={{ alignSelf: 'center' }} >
            <Overview style={{ justifyContent: 'flex-start' }}>
                <h1>{client.name} Analytics</h1>
                <hr style={{ width: '100%' }} />
            </Overview>
            <Overview>
                {!isCashFlowPopupVisible &&
                    (
                        <>
                            {!isBankAccountsVisible && (
                                <CompanyInfoCard
                                    handleBankVisibility={() => setIsBankAccountsVisible(true)}
                                    customStyles={{ alignContent: "space-between" }}
                                    isFlipped={isFlipped}
                                    setIsFlipped={toggleIsFlipped}
                                />)}
                            {isBankAccountsVisible && (
                                <BankAccountList
                                    handleBankVisibility={() => setIsBankAccountsVisible(false)}
                                    isFlipped={isFlipped}
                                    setIsFlipped={toggleIsFlipped}
                                />)}
                            <ProfitAndLossCard />
                        </>
                    )
                }
                {isCashFlowPopupVisible &&
                    (
                        <CashFlowPopupCard clients={clients} id={id} />
                    )}
                <CardContainer>
                    <CompanyInfo>
                        <div>
                            <div>
                                <h4>Cash Flow</h4>
                                <Description>Apr 2023 - Oct 2023</Description>
                            </div>
                        </div>
                        <div>
                            <div style={{ color: "#59b398" }}>
                                {(!isCashFlowPopupVisible || isCashFlowPopupVisible) && (
                                    <p onClick={toggleCashFlowPopup} style={{ cursor: "pointer", color: "#FF6448" }}>
                                        {isCashFlowPopupVisible ? "Close" : "View more"}
                                    </p>
                                )}
                            </div>
                        </div>
                    </CompanyInfo>
                    <CashFlowSvgStyle style={{ marginTop: "40px" }}>
                        {client.cashflow}
                    </CashFlowSvgStyle>
                </CardContainer>
                <CardContainer>
                    <KpiIndicators />
                </CardContainer>
                <Loans />
                <RecentCfoNotifications />
            </Overview >
        </Overview>
    );
};

export default AnalyticsActive;
