export const AverageTransport = {
    currentratio: 2,
    quickratio: 0.32,
    cashratio: 0.40,
    operatingprofitmargin: 5.21,
    netprofitpargin: 4.53,
    returnonassets: 6.20,
    returnonequity: 22.28,
    inventoryturnover: 28,
    grossprofitmargin: 77.12,
    debttoequity: 1.06,
    debtratio: 0.75,
}

export const AverageStoreGeneral = {
    currentratio: 2,
    quickratio: 0.10,
    cashratio: 0.40,
    operatingprofitmargin: 2.31,
    netprofitpargin: 2.83,
    returnonassets: 6.50,
    returnonequity: 19.38,
    inventoryturnover: 28,
    grossprofitmargin: 24.97,
    debttoequity: 0.79,
    debtratio: 0.75,
}

export const AverageManufacturingBeverage = {
    currentratio: 2,
    quickratio: 0.32,
    cashratio: 0.40,
    operatingprofitmargin: 18.35,
    netprofitpargin: 11.70,
    returnonassets: 5.24,
    returnonequity: 24.46,
    inventoryturnover: 28,
    grossprofitmargin: 52.42,
    debttoequity: 0.39,
    debtratio: 0.75,
}

