import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { BoldTitle, CFONotificationBlock, CFONotificationCard, CardContainer, CenteredButton, NotificationButton } from '../AnalyticsActive1/AnalyticsActive.styled';
import { CompanyInfo, Description } from '../Analytics/Analyticsempty.styled';

const HomeRecentNotification = ({ customStyles }) => {
    const [isFirstCardFlipped, setFirstCard] = useState(false);
    const [isSecondCardFlipped, setSecondCard] = useState(false);
    const navigate = useNavigate();

    const toggleFirstCard = () => {
        setFirstCard(!isFirstCardFlipped);
    };
    const toggleShinkageCard = () => {
        setSecondCard(!isSecondCardFlipped);
    };



    let content = (
        <>
            <CardContainer style={{ flexDirection: "column", ...customStyles }}>
                <CompanyInfo>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <h4>Recent CFO Notifications</h4>
                        <p style={{ color: "#FC6E45", textDecoration: "none", cursor: 'pointer' }} onClick={() => navigate('/cfo')}>See all</p>
                    </div>
                </CompanyInfo>
                <Description style={{ marginTop: "10px" }}>1 New Notification</Description><br />
                <CFONotificationBlock>
                    <CFONotificationCard className={isFirstCardFlipped ? 'flipped' : ''} style={{ marginTop: "0px" }}>
                        <div>
                            <BoldTitle>
                                <div >Liquidity ratio below optimal</div> <p onClick={toggleFirstCard} style={{ cursor: "pointer" }}>
                                    {isFirstCardFlipped ? <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#000000", }} /> : <FontAwesomeIcon icon={faEye} style={{ color: "#000000", }} />}</p>
                            </BoldTitle>
                            <p>
                                {isFirstCardFlipped ? (
                                    <>
                                        Send a quote for Small Business Loan: 12 months, 11% interest rate.
                                        <CenteredButton>
                                            <NotificationButton>Send</NotificationButton>
                                        </CenteredButton>
                                    </>
                                ) : (
                                    <>
                                        Comtrade’s liquidity ratio is lower than the optimal value of 1.1. Have you considered offering them a loan?{' '}
                                    </>
                                )}
                            </p>
                        </div>
                    </CFONotificationCard>
                    <CFONotificationCard>
                        <div>
                            <BoldTitle>
                                <div>Sales trend low</div> &#10006;
                            </BoldTitle>
                            <p>
                                Impol’s sales trend is 8% lower compared to previous month.
                            </p>
                        </div>
                    </CFONotificationCard>
                    <CFONotificationCard className={isSecondCardFlipped ? 'flipped' : ''} style={{ background: "#FC6E45", }} >
                        <div>
                            <BoldTitle>
                                <div>High Inventory Shrinkage </div> <p onClick={toggleShinkageCard} style={{ cursor: "pointer" }}>
                                    {isSecondCardFlipped ? <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#000000", }} /> : <FontAwesomeIcon icon={faEye} style={{ color: "#000000", }} />}</p>
                            </BoldTitle>
                            <p>
                                {isSecondCardFlipped ? (
                                    <>
                                        La Fantana’s inventory shrinkage costs have increased by 31% during previous 3 months. Have you considered offering them to secure their assets?{' '}
                                        <CenteredButton>
                                            <NotificationButton>Book</NotificationButton>
                                        </CenteredButton>
                                    </>
                                ) : (
                                    <>
                                        La Fantana’s inventory shrinkage costs have increased by 31% during previous 3 months. Have you considered offering them to secure their assets?.{' '}
                                    </>
                                )}

                            </p>
                        </div>
                    </CFONotificationCard>
                </CFONotificationBlock>
            </CardContainer >
        </>
    );
    return content;
}
export default HomeRecentNotification;