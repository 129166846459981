import { useState } from "react";

const useClientsSorting = () => {
    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };

    const handleRowClick = (client) => {
        if (client.status === "ACTIVE") {
            window.location.href = `/${client.id}/analyticsactive`;
        } else if (client.status === "INACTIVE") {
            if (client.id === 41) {
                window.location.href = `/17/analyticsempty`
            }
            else {
                window.location.href = `/${client.id}/analyticsempty`;
            }
        }
    };

    return { sortField, sortOrder, handleSort, handleRowClick };
};

export default useClientsSorting;
