import { useState, useEffect } from "react";

export function useCustomTable(data, state) {
    const [sortField, setSortField] = useState("reward");
    const [sortOrder, setSortOrder] = useState("asc");
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(state);
    const [selectedProgram, setSelectedProgram] = useState("");
    const [clientOptions, setClientOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [programOptions, setProgramOptions] = useState([]);
    useEffect(() => {
        const uniqueClients = [...new Set(data.map(item => item.client))];
        const uniqueStatuses = [...new Set(data.map(item => item.status))];
        const uniquePrograms = [...new Set(data.map(item => item.program))];

        setClientOptions(uniqueClients);
        setStatusOptions(uniqueStatuses);
        setProgramOptions(uniquePrograms);
    }, [data]);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };

    const filteredItems = data.filter((item) => {
        const clientMatch = !selectedClient || item.client === selectedClient;
        const statusMatch = !selectedStatus || item.status === selectedStatus;
        const programMatch = !selectedProgram || item.program === selectedProgram;
        return clientMatch && statusMatch && programMatch;
    });

    const sortedItems = [...filteredItems].sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (aValue === bValue) {
            return 0;
        }

        if (sortOrder === "asc") {
            if (aValue === "-" || aValue === undefined) {
                return 1;
            } else if (bValue === "-" || bValue === undefined) {
                return -1;
            } else {
                return aValue < bValue ? -1 : 1;
            }
        } else {
            if (aValue === "-" || aValue === undefined) {
                return -1;
            } else if (bValue === "-" || bValue === undefined) {
                return 1;
            } else {
                return bValue < aValue ? - 1 : 1;
            }
        }
    });

    return {
        sortField,
        sortOrder,
        selectedClient,
        setSelectedClient,
        selectedStatus,
        setSelectedStatus,
        selectedProgram,
        setSelectedProgram,
        clientOptions,
        statusOptions,
        programOptions,
        handleSort,
        filteredItems,
        sortedItems,
    };
}
