import React from "react";
import { useParams } from "react-router-dom";
import { Client, Overview } from "../Home/Home.styled";
import { Circle, Initial, DivideBlocks, Description, H2Margin, RightGroupCards, DescriptionMargin, AlignCards, AnalyticsTopLeftText, LeftGroupCards, AnalyticsBlock, AnalyticsBottomText, EmptyCompanyInfo } from "./Analyticsempty.styled";
import QuestionMark, { CashFlowSvg } from "../../svg/Analytics.svg";
import ClientsData from "../../data/ClientsData";
import EmptyProfitAndLoss from "../../svg/AnalyticsEmptySvg";

const liquidityRatios = [
    { title: "Current Ratio" },
    { title: "Quick Ratio" },
    { title: "Cash Ratio" },
];

const profitabilityRatios = [
    { title: "Operating Profit Margin" },
    { title: "Net Profit Margin" },
    { title: "Return on Asset" },
    { title: "Return on Equity" },
    { title: "Inventory Turnover" },
    { title: "Gross Profit Margin" },
];

const debtRatios = [
    { title: "Debt-to-Equity" },
    { title: "Debt Ratio" }
];

const clients = ClientsData;

const AnalyticsBlockGroup = ({ data }) => (
    <AlignCards>
        {data.map((item, index) => (
            <AnalyticsBlock key={index}>
                <AnalyticsTopLeftText>{item.title}</AnalyticsTopLeftText>
                <AnalyticsBottomText>-</AnalyticsBottomText>
            </AnalyticsBlock>
        ))}
    </AlignCards>
);

const AnalyticsSection = ({ title, data }) => (
    <div>
        <AlignCards>
            <span>{title} <QuestionMark /></span>
        </AlignCards>
        <AnalyticsBlockGroup data={data} />
    </div>
);

const Analyticsempty = () => {
    const { id } = useParams();
    const client = clients.find(client => client.id.toString() === id.trim());

    return (
        <>
            <Overview style={{ flexWrap: "nowrap" }}>
                <EmptyCompanyInfo>
                    <Circle>
                        <Initial>{client.name.charAt(0)}</Initial>
                    </Circle>
                    <div>
                        <div>{client.name}</div>
                        <Description>{client.industry}</Description>
                    </div>
                </EmptyCompanyInfo>
                <Client>INVITE THIS CLIENT TO GENEQTY</Client>
            </Overview>
            <DivideBlocks>
                <LeftGroupCards>
                    {[
                        { title: "Liquidity ratios", data: liquidityRatios },
                        { title: "Profitability ratios", data: profitabilityRatios },
                        { title: "Debt ratios", data: debtRatios }
                    ].map((section, index) => (
                        <AnalyticsSection key={index} title={section.title} data={section.data} />
                    ))}
                </LeftGroupCards>
                <RightGroupCards>
                    <H2Margin>Cash Flow</H2Margin>
                    <CashFlowSvg />
                    <H2Margin>Profit and Loss</H2Margin>
                    <DescriptionMargin>10 Aug 2023</DescriptionMargin>
                    <EmptyProfitAndLoss />
                </RightGroupCards>
            </DivideBlocks>
        </>
    );
};

export default Analyticsempty;
