import styled from "styled-components";


export const TableContainer = styled.div`
width: 100%;
 @media (max-width: 1536px) {
  justify-content: center;
 }
`;

export const TableData = styled.td`
  position: relative;
  text-align: center;
  padding: 10px;
  color: ${props => (props.headers ? "white" : "#9AAAB2")};
  background-color: ${props =>
    props.headers ? "transparent" : "rgba(255, 255, 255, 0.08)"};
  border-top: ${props => (props.merged ? "none" : "1px solid rgba(0, 0, 0, 0.1)")};
  &:first-child {
    color: white;
    border-radius: 20px 0 0 20px;
  }
  &:last-child {
    color: white;
    border-radius: 0 20px 20px 0;
  }
  width: ${props => (props.inviteButton ? "auto" : "auto")};
  white-space: nowrap;
  padding-right: ${props => (props.inviteButton ? "40px" : "10px")};
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
`;

export const SortIcon = styled.button`
  width: 35px;
  height: 25px;
  background: #232D4080;
  color: #879CA7;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;



export const InviteButton = styled.button`
  background-color: #9AAAB2;
  color: #101B2F;
  border: none;
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
`;

export const SendInvitePopupContainer = styled.div`
position: fixed;
display: flex;
flex-direction: column;
align-items: center;
top: 20px;
right: 20px;
background: #232d40;
border-radius: 39px;
padding: 10px 20px;
width: 20%;
text-wrap: wrap;
color: #fff;
font-weight: 500;
`;
