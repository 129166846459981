import styled from 'styled-components';

const CircleBase = `
  width: 70px;
  height: 70px;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

export const WhiteCircle = styled.div`
  ${CircleBase};
  background: white;
`;

export const BlackLetter = styled.span`
  font-size: 36px;
  font-weight: bold;
  color: black;
`;

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const LearnMoreButton = styled.button`
  width: 50%;
  height: 40px;
  align-self: center;
  border-radius: 23px;
  background: white;
  opacity:100%;
  color: black;
  border: none;
  cursor: pointer;
  `

export const CFONotificationCard = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
width: 95%;
background: #9AAAB2;
border-radius: 16px;
padding: 15px;
margin-top: 10px;
margin-bottom: 10px;
color: black;
transition: transform 0.5s ease-in-out;

&.flipped {
  transform: rotateX(360deg);
}
`;

export const CFONotificationBlock = styled.div`
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  
  ${CFONotificationCard}:first-child {
    background: #FC6E45;
    ${LearnMoreButton} {
      opacity: 0.7;
    }
  }
`;
export const CenteredButton = styled.div`
display:flex;
justify-content: center;
`
export const NotificationButton = styled.button`
  background-color: white;
  color: black;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;


export const BoldTitle = styled.b`
display: flex;
justify-content: space-between;
padding-bottom: 5px;
`

export const HorisontalLine = styled.hr`
max-width: 1366px;
`

export const BusinessHealthScore = styled.div`
display:flex;
flex-direction: row;
>:first-child {
    display: flex;
    margin-right: 40px;
    flex-direction: column;
    justify-content: center;
}
`


export const CardContainer = styled.div`
display: flex;
align-content: flex-start;
flex-wrap: wrap;
flex: 0 0 calc(33.33% - 20px);
margin: 10px;
background: #242d41;
color: white;
border-radius: 10px;
padding: 20px;
box-sizing: border-box;
height: 400px;
align-items: flex-start;
transition: transform 0.5s ease-in-out;
&.flipped {
  transform: rotateX(360deg);
}

/* Responsive Styles */
@media (max-width: 768px) {
  flex: 0 0 calc(50% - 20px);
  min-width: 100%;
  flex-wrap: wrap;
  margin-left: 0;
}

@media (max-width: 991px) {
  flex: 0 0 calc(50% - 20px);
  min-width: 100%;
  flex-wrap: wrap;
  margin-left: 0;
}

@media (max-width: 1493px) {
  flex: 0 0 calc(50% - 20px);
  flex-wrap: wrap;
  margin-left: 0;
  height: 300px;
  overflow-y: auto;
}

@media (max-width: 1632px) {
  flex: 0 0 calc(50% - 20px);
  flex-wrap: wrap;
  margin-left: 0;
  height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
}
`;


export const ProfitAndLossSvgStyle = styled.div`
display: flex;
width: 100%;
justify-content: center;
`
export const CashFlowSvgStyle = styled.div`
display: flex;
width: 100%;
justify-content: center;
`

export const Square = styled.div`
  flex-basis: calc(50% - 10px);
  background-color: #343d50;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  height: 130px;
  justify-content: space-between;

  div:first-child {
    width: 100%;
  }
  div:nth-child(2) {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    min-width:95%;
  }
`;


export const KpiGreenFont = styled.p`
font-size: 32px;
color:#6EB4A1;
`

export const KpiRedFont = styled.p`
color: #f75a4f;
font-size: 34px;
width: 40px;
height: 40px;
`

export const KpiOrangeFont = styled.p`
color: #BA9544;
font-size: 32px;
`

export const KpiOverFlow = styled.div`
display: flex;
flex-wrap: wrap;
overflow-y: auto;
max-height:300px;
width:100%;
`

export const InLineDescription = styled.div`
  display:flex;
  flex-direction: row;
`


export const AccountsSquare = styled.div`
flex-basis: calc(50% - 10px); 
background-color: #343D50;
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 10px;
margin-right: 10px;
margin-bottom: 10px;
justify-content: space-between;
`



export const LoansSquare = styled.div`
flex-basis: calc(50% - 10px);
background-color: #343d50;
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: center;
margin-right: 10px;
margin-bottom: 10px;
justify-content: space-between;
& > div:first-child {
  font-weight: bold;
}
`;